import PropTypes from 'prop-types'

export const RoutePath = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.exact({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.exact({
      noScroll: PropTypes.bool,
      redirect: PropTypes.string,
    }),
  }),
])

export const RouteProps = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

// Payment Method
// -----------------------------------------------------------------------------
export const ACH = 'ach'
export const WIRE = 'wire'
export const CHECK = 'check'
export const OTHER = 'other'

export const PAYMENT_METHOD_TYPE = {
  WIRE: WIRE,
  ACH: ACH,
  CHECK: CHECK,
}
export const PAYMENT_METHOD_OPTIONS = {
  [ACH]: 'ACH (Electronic Check)',
  [WIRE]: 'Wire',
  [CHECK]: 'Check',
}

export const PAYMENT_METHOD_OPTIONS_DISTRIBUTION = {
  [ACH]: 'ACH (Electronic Check)',
  [CHECK]: 'Check',
}

export const FINANCIAL_ISTITUTION = 'financialInstitution'
export const REGISTERED_INVESTMENT = 'registeredInvestment'
export const CHECK_INVESTMENT_PROFILE_OPTIONS = {
  [REGISTERED_INVESTMENT]:
    'The company will send a check to the name and address you have registered your investment.',
  [FINANCIAL_ISTITUTION]: 'Send to My Financial Institution',
}

export const PAYMENT_TYPE_PERSONAL = 'personal'
export const PAYMENT_TYPE_BUSINESS = 'business'
export const ACCOUNT_TYPE_CHECKING = 'checking'
export const ACCOUNT_TYPE_SAVINGS = 'savings'

export const ACCOUNT_TYPE_OPTIONS_DISTRIBUTION = {
  [ACCOUNT_TYPE_CHECKING]: 'Checking',
  [ACCOUNT_TYPE_SAVINGS]: 'Savings',
}

export const PAYMENT_TYPE_OPTIONS_DISTRIBUTION = {
  [PAYMENT_TYPE_PERSONAL]: 'Personal',
  [PAYMENT_TYPE_BUSINESS]: 'Business',
}

export const PAYMENT_METHOD_OPTIONS_NON_FA = {
  ...PAYMENT_METHOD_OPTIONS,
  [CHECK]: 'Check',
  [OTHER]: 'Other',
}

const STATUS_RECEIVED = 'received'
const STATUS_CONFIRMED = 'confirmed'
const STATUS_IN_ESCROW = 'in_escrow'
const STATUS_DISBURSED = 'disbursed'

export const PAYMENT_STATUSES_NON_FA = {
  [STATUS_RECEIVED]: 'Received',
  [STATUS_CONFIRMED]: 'Confirmed',
  [STATUS_DISBURSED]: 'Disbursed',
  [OTHER]: 'Other',
  [STATUS_IN_ESCROW]: 'In Escrow (Legacy)',
}

export const PaymentMethod = PropTypes.shape({
  createdAt: PropTypes.string,
  investorUuid: PropTypes.string,
  type: PropTypes.oneOf(Object.values(PAYMENT_METHOD_TYPE)),
  updatedAt: PropTypes.string,
  userUuid: PropTypes.string,
  uuid: PropTypes.string,
})

export const PaymentMethodNonFA = PropTypes.shape({
  amount: PropTypes.number,
  createdAt: PropTypes.string,
  details: PropTypes.string,
  fundsClearDate: PropTypes.string,
  inEscrowDate: PropTypes.string,
  investment: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  method: PropTypes.oneOf(Object.keys(PAYMENT_METHOD_OPTIONS_NON_FA)),
  methodDescription: PropTypes.string,
  receivedDate: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(PAYMENT_STATUSES_NON_FA)),
  statusDescription: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
})

// ACH
// ---------------------------------------------------------------------------
export const ACCOUNT_TYPE_OPTIONS = {
  checking: 'Checking',
  savings: 'Saving',
}
export const CHECK_TYPE_OPTIONS = {
  business: 'Business',
  personal: 'Personal',
}
export const AchSettings = PropTypes.shape({
  accountNumber: PropTypes.string,
  accountType: PropTypes.oneOf(Object.keys(ACCOUNT_TYPE_OPTIONS)),
  checkType: PropTypes.oneOf(Object.keys(CHECK_TYPE_OPTIONS)),
  createdAt: PropTypes.string,
  nameOnAccount: PropTypes.string,
  owner: PropTypes.string,
  routingNumber: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string, // might be missing
})

//Distribution instructions
// -----------------------------------------------------------------------------

export const DistributionInstructions = PropTypes.shape({
  paymentMethod: PropTypes.oneOf([ACH, CHECK]),
  authorizeCompany: PropTypes.string,
  authorityRemain: PropTypes.string,
  companyDeposits: PropTypes.string,
  acknowledgeFunds: PropTypes.string,
  noneCompanyAffiliates: PropTypes.string,
  acknowledgeDistributions: PropTypes.string,
  capitalReturned: PropTypes.string,
  financialInstitutionName: PropTypes.string,
  bankAccountName: PropTypes.string,
  routingNumber: PropTypes.string,
  accountNumber: PropTypes.string,
  unmaskedAccountNumber: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  uuid: PropTypes.string,
})

// Offerings
// -----------------------------------------------------------------------------

export const OfferingsStatus = {
  pending: 'pending',
  no_escrow: 'no_escrow',
  opened: 'opened',
  paused: 'paused',
  closed: 'closed',
  cancelled: 'cancelled',
}

export const OfferingsStatusOptions = {
  [OfferingsStatus.pending]: 'Pending',
  [OfferingsStatus.no_escrow]: 'No Escrow',
  [OfferingsStatus.opened]: 'Opened',
  [OfferingsStatus.paused]: 'Paused',
  [OfferingsStatus.closed]: 'Closed',
  [OfferingsStatus.cancelled]: 'Cancelled',
}

export const OfferingsUpdatesStatusOptions = {
  [OfferingsStatus.pending]: 'Pending',
  [OfferingsStatus.opened]: 'Opened',
  [OfferingsStatus.paused]: 'Paused',
  [OfferingsStatus.closed]: 'Closed',
}
export const OFFERING_UPDATES_DOCUMENT_TYPES = {
  AUDIT: 'audit',
  FINANCIAL_STATEMENT: 'financial_statement',
  FUND_REPORTS: 'fund_reports',
  OTHER: 'other',
}

export const OFFERING_UPDATES_DOCUMENTS_CHOICES = {
  [OFFERING_UPDATES_DOCUMENT_TYPES.AUDIT]: 'Audit',
  [OFFERING_UPDATES_DOCUMENT_TYPES.FINANCIAL_STATEMENT]: 'Financial Statement',
  [OFFERING_UPDATES_DOCUMENT_TYPES.FUND_REPORTS]: 'Fund Reports',
  [OFFERING_UPDATES_DOCUMENT_TYPES.OTHER]: 'Other',
}

export const OfferingTypes = {
  regD506c: '506(c)',
  regD506b: '506(b)',
  regCF: 'Reg CF',
}

export const GrowthIncomeTaxAdvantageTypes = {
  all: 'All',
  growth: 'Growth',
  growthAndIncome: 'Growth & Income',
  growthOrTax: 'Growth/Tax',
  growthTax: 'Growth/Tax',
  income: 'Income',
  incomeAndGrowth: 'Income & Growth',
  incomeOrGrowth: 'Income OR Growth',
  taxAdvantaged: 'Tax Advantaged (QSBS & OZ)',
}

export const MinimumInvestmentTypeAll = 'all'
export const MinimumInvestmentType25 = '25000'
export const MinimumInvestmentType50 = '50000'
export const MinimumInvestmentType100 = '100000'
export const MinimumInvestmentTypePlus = '100001+'

export const MinInvesmentTypes = [
  { value: MinimumInvestmentTypeAll, label: 'All' },
  { value: MinimumInvestmentType25, label: '25k or less' },
  { value: MinimumInvestmentType50, label: '50k or less' },
  { value: MinimumInvestmentType100, label: '100k or less' },
  { value: MinimumInvestmentTypePlus, label: '100k+' },
]

export const OfferingRegistrationTypes = {
  ...OfferingTypes,
  all: 'All',
}

export const offeringTileOptions = {
  ['1031']: '1031',
  Private: 'Private',
  QOZ: 'QOZ',
  all: 'All',
}

export const offeringChiselAndStoneOptions = {
  // all: 'All',
  [1]: 'Chisel and Stone',
  [0]: '1776ing',
}

export const OfferingTypesOptions = {
  [OfferingTypes.regD506c]: OfferingTypes.regD506c,
  [OfferingTypes.regD506b]: OfferingTypes.regD506b,
}

export const GrowthIncomeTaxAdvantageOptions = {
  all: GrowthIncomeTaxAdvantageTypes.all,
  [GrowthIncomeTaxAdvantageTypes.growth]: GrowthIncomeTaxAdvantageTypes.growth,
  [GrowthIncomeTaxAdvantageTypes.growthAndIncome]: GrowthIncomeTaxAdvantageTypes.growthAndIncome,
  [GrowthIncomeTaxAdvantageTypes.growthOrTax]: GrowthIncomeTaxAdvantageTypes.growthOrTax,
  [GrowthIncomeTaxAdvantageTypes.income]: GrowthIncomeTaxAdvantageTypes.income,
  [GrowthIncomeTaxAdvantageTypes.incomeAndGrowth]: GrowthIncomeTaxAdvantageTypes.incomeAndGrowth,
  [GrowthIncomeTaxAdvantageTypes.incomeOrGrowth]: GrowthIncomeTaxAdvantageTypes.incomeOrGrowth,
  [GrowthIncomeTaxAdvantageTypes.taxAdvantaged]: GrowthIncomeTaxAdvantageTypes.taxAdvantaged,
}

export const OfferingRegistrationTypesOptions = {
  all: OfferingRegistrationTypes.all,
  [OfferingRegistrationTypes.regD506b]: OfferingRegistrationTypes.regD506b,
  [OfferingRegistrationTypes.regD506c]: OfferingRegistrationTypes.regD506c,
}

export const OfferingsPerPageOptions = { 12: 12, 24: 24, 48: 48 }

export const CUSTOM_FIELD = PropTypes.shape({
  createdAt: PropTypes.string,
  fieldDescription: PropTypes.string,
  fieldEntityType: PropTypes.string,
  fieldName: PropTypes.string,
  fieldSlug: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  value: PropTypes.string,
})

export const OFFERING_CUSTOM_SURVEY_LABEL = 'offeringCustomSurveyLabel'
export const OFFERING_CUSTOM_ADDITIONAL_SURVEY_LABEL = 'offeringAdditionalSurveyLabel'
export const OFFERING_CUSTOM_SECOND_ADDITIONAL_SURVEY_LABEL = 'offeringAdditionalSurvey2Label'
export const OFFERING_EXTRA_WIRE_INFO = 'offeringExtraWireInfo'
export const OFFERING_EXTRA_ACH_INFO = 'offeringExtraAchInfo'
export const OFFERING_SECOND_PAGE_LABEL = 'offeringSecondPageLabel'
export const OFFERING_THIRD_PAGE_LABEL = 'offeringThirdPageLabel'
export const OFFERING_506C_NET_WORTH_STATIC_TEXT_SLUG = 'ShowLiabilitiesOverride'
export const OFFERING_AMOUNT_STEP_LABEL = 'offeringAmountStepLabel'
export const OFFERING_AMOUNT_FIELD_LABEL = 'offeringAmountFieldLabel'
export const OFFERING_COMPLETED_DIALOG_TEXT = 'offeringCompletedDialogText'
export const OFFERING_IPP_FORM_LABEL = 'offeringIppFormLabel'

export const OFFERING_CUSTOM_FIELDS_SLUGS = [
  OFFERING_CUSTOM_SURVEY_LABEL,
  OFFERING_CUSTOM_ADDITIONAL_SURVEY_LABEL,
  OFFERING_EXTRA_WIRE_INFO,
  OFFERING_EXTRA_ACH_INFO,
  OFFERING_506C_NET_WORTH_STATIC_TEXT_SLUG,
  OFFERING_AMOUNT_STEP_LABEL,
  OFFERING_COMPLETED_DIALOG_TEXT,
  OFFERING_IPP_FORM_LABEL,
]

export const OFFERING_IPP_TYPE_NONE = 'none'
export const OFFERING_IPP_TYPE_IPP = 'ipp'
export const OFFERING_IPP_TYPE_IPP_WITH_INVESTMENT = 'ippWithInvestment'

export const REG_BI_OFFERING = PropTypes.shape({
  officialNameRegistrationType: PropTypes.string,
  ppmDateTerminationExpectedHold: PropTypes.string,
  liquidityRedemption: PropTypes.string,
  investmentMinimum: PropTypes.string,
  targetReturnsYield: PropTypes.string,
  investorClaimCapitalStack: PropTypes.string,
  offeringExpensesSyndicationCosts: PropTypes.string,
  compensation: PropTypes.string,
  tax: PropTypes.string,
})

export const Offering = PropTypes.shape({
  accreditedUS: PropTypes.bool,
  additionalSurveyUuid: PropTypes.string,
  additionalSurvey2Uuid: PropTypes.string,
  closedAt: PropTypes.string,
  createdAt: PropTypes.string,
  customCheckboxText: PropTypes.string,
  dataReviewed: PropTypes.bool,
  description: PropTypes.string,
  disableUnitsCustodian: PropTypes.bool,
  disableDistributionInstructions: PropTypes.bool,
  dontSendToClient: PropTypes.bool,
  enabled: PropTypes.bool,
  enableBeneficiaries: PropTypes.bool,
  enable506cStep: PropTypes.bool,
  enabled506cOptions: PropTypes.string,
  investmentIncrementAmount: PropTypes.number,
  investmentDiscount: PropTypes.bool,
  investors: PropTypes.number,
  imageUrl: PropTypes.string,
  imageUuid: PropTypes.string,
  regBiOffering: REG_BI_OFFERING,
  ippType: PropTypes.oneOf([
    OFFERING_IPP_TYPE_NONE,
    OFFERING_IPP_TYPE_IPP,
    OFFERING_IPP_TYPE_IPP_WITH_INVESTMENT,
  ]),
  videoUrl: PropTypes.string,
  autoPlayEnabled: PropTypes.bool,
  issuerName: PropTypes.string,
  logoUrl: PropTypes.string,
  logoUuid: PropTypes.string,
  maxAmount: PropTypes.number,
  maxInvestmentAmount: PropTypes.number,
  minAmount: PropTypes.number,
  minInvestmentAmount: PropTypes.number,
  displayMinInvestmentAmount: PropTypes.number,
  name: PropTypes.string,
  offeringClosedMessage: PropTypes.string,
  offeringOpenedMessage: PropTypes.string,
  offeringWeight: PropTypes.object,
  nonAccreditedUS: PropTypes.bool,
  nonUS: PropTypes.bool,
  sharePrice: PropTypes.number,
  showAdvisorSignature: PropTypes.bool,
  showAdvisorSignatureAfterClient: PropTypes.bool,
  showSupervisorSignature: PropTypes.bool,
  status: PropTypes.oneOf(Object.keys(OfferingsStatus)), // must be opened
  targetAmount: PropTypes.number,
  title: PropTypes.string,
  testSurveyUuid: PropTypes.string,
  isIntegerSharePrice: PropTypes.bool,
  surveyName: PropTypes.string,
  surveyLabel: PropTypes.string,
  surveyUuid: PropTypes.string,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
  useFundAmerica: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
  wirePaymentMethod: PropTypes.bool.isRequired,
  achPaymentMethod: PropTypes.bool.isRequired,
  checkPaymentMethod: PropTypes.bool.isRequired,
  checkInstructions: PropTypes.string,
  isIoi: PropTypes.bool,
  hideAccreditationType: PropTypes.bool,
  isIoi2: PropTypes.bool,
  totalCommitted: PropTypes.number,
  totalReceived: PropTypes.number,
  subscriptionAgreementRequiredCheckboxText: PropTypes.string,
  subscriptionAgreementRequiredCheckboxEnabled: PropTypes.bool,

  accreditationData: PropTypes.shape({
    advisor: PropTypes.string,
    gatherSpecificDocuments: PropTypes.string,
    email: PropTypes.string,
    finra: PropTypes.string,
    thirdParty: PropTypes.string,
    offline: PropTypes.string,

    advisorAdvisor: PropTypes.string,
    gatherSpecificDocumentsAdvisor: PropTypes.string,
    emailAdvisor: PropTypes.string,
    finraAdvisor: PropTypes.string,
    thirdPartyAdvisor: PropTypes.string,
    offlineAdvisor: PropTypes.string,
  }),

  offeringCustomField: PropTypes.arrayOf(CUSTOM_FIELD),
})

export const FAOffering = PropTypes.shape({
  id: PropTypes.string,
  url: PropTypes.string,
  accept_investments: PropTypes.bool,
  accredited_investors: PropTypes.bool,
  ach_deposit_amount: PropTypes.string,
  ach_deposit_max_percent: PropTypes.string,
  ach_deposit_release_at: PropTypes.string,
  ach_limit: PropTypes.string,
  amount: PropTypes.string,
  can_disburse: PropTypes.bool,
  check_mailing_address: PropTypes.string,
  check_mailing_instructions: PropTypes.string,
  closed_at: PropTypes.string,
  delay_aml_until_funds_received: PropTypes.bool,
  description: PropTypes.string,
  eb5: PropTypes.bool,
  entity_url: PropTypes.string,
  escrow_closes_at: PropTypes.string,
  escrow_status: PropTypes.oneOf(Object.keys(OfferingsStatus)),
  escrow_term_days: PropTypes.number,
  funds_disbursable: PropTypes.string,
  funds_in_escrow: PropTypes.string,
  funds_invested: PropTypes.string,
  funds_received: PropTypes.string,
  funds_refunded: PropTypes.string,
  funds_transfer_methods: PropTypes.array,
  funds_unavailable: PropTypes.string,
  investment_increment_amount: PropTypes.string,
  max_amount: PropTypes.string,
  max_investment_amount: PropTypes.string,
  min_amount: PropTypes.string,
  min_investment_amount: PropTypes.string,
  minimum_met: PropTypes.bool,
  name: PropTypes.string,
  non_accredited_investors: PropTypes.bool,
  non_us_investors: PropTypes.bool,
  opened_at: PropTypes.string,
  payment_reference: PropTypes.string,
  regulatory_exemption: PropTypes.string,
  regulatory_exemption_details: PropTypes.string,
  security_url: PropTypes.string,
  type: PropTypes.string,
  updated_at: PropTypes.string,
  us_investors: PropTypes.bool,
  us_states_only: PropTypes.array,
  use_broker_dealer_service: PropTypes.bool,
  wire_details: PropTypes.shape({
    bank_address: PropTypes.string,
    bank_name: PropTypes.string,
    bank_phone: PropTypes.string,
    routing_number: PropTypes.string,
    swift_code: PropTypes.string,
    account_number: PropTypes.string,
    beneficiary_name: PropTypes.string,
    beneficiary_address: PropTypes.string,
  }),
  object: PropTypes.string,
  fundingType: PropTypes.string,
})

export const Portal = PropTypes.shape({
  copyright: PropTypes.string,
  createdAt: PropTypes.string,
  dedicatedClosedOfferingsPage: PropTypes.string,
  dedicatedOfferingsPage: PropTypes.string,
  default: PropTypes.bool,
  defaultPortalUrl: PropTypes.string,
  emailLogoUrl: PropTypes.string,
  emailLogoUuid: PropTypes.string,
  faviconUrl: PropTypes.string,
  faviconUuid: PropTypes.string,
  imageUrl: PropTypes.string,
  imageUuid: PropTypes.string,
  logoUrl: PropTypes.string,
  logoUuid: PropTypes.string,
  mailerFromAddress: PropTypes.string,
  mailerFromName: PropTypes.string,
  mfaRequired: PropTypes.bool,
  name: PropTypes.string,
  offeringsPageTileDisableActions: PropTypes.bool,
  portalThemeUuid: PropTypes.string,
  poweredByLogoUrl: PropTypes.string,
  poweredByLogoUuid: PropTypes.string,
  relatedOfferingsUuids: PropTypes.arrayOf(PropTypes.string),
  stylesheet: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.string,
  userUuid: PropTypes.string,
  uuid: PropTypes.string,
  wCodePrefix: PropTypes.string,
  showIppSurvey: PropTypes.bool,
})

export const OfferingInformation = PropTypes.shape({
  highRisk: PropTypes.bool,
  accreditedInvestors: PropTypes.bool,
  nonAccreditedInvestors: PropTypes.bool,
  usInvestors: PropTypes.bool,
  restrictToSpecificUsStates: PropTypes.bool,
  nonUsInvestors: PropTypes.bool,
  useBrokerDealerService: PropTypes.bool,
  delayAmlUntilFundsReceived: PropTypes.bool,
  name: PropTypes.string,
  closesAt: PropTypes.string,
  amount: PropTypes.number,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
  minInvestmentAmount: PropTypes.number,
  maxInvestmentAmount: PropTypes.number,
  investmentIncrementAmount: PropTypes.number,
  fundsTransferMethods: PropTypes.string,
  displayPromoCodes: PropTypes.bool,
  displayFinancialAdvisor: PropTypes.bool,
  applyAdministrationFee: PropTypes.bool,
})

export const OfferingSecurity = PropTypes.shape({
  ceremonialCertificates: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  issuerMustReviewTransfer: PropTypes.bool,
  legends: PropTypes.string,
  name: PropTypes.string,
  noPartialTransfer: PropTypes.string,
  noTransferToNonAccredited: PropTypes.string,
  noTransferToNonAccreditedDays: PropTypes.string,
  noTransferToNonStateResident: PropTypes.string,
  nonAccreditedMustHold: PropTypes.string,
  nonAccreditedMustHoldDays: PropTypes.string,
  originalOwnerMustHold: PropTypes.string,
  originalOwnerMustHoldDays: PropTypes.string,
  sendEmails: PropTypes.bool,
  testingTheWaters: PropTypes.string,
  type: PropTypes.string,
  unitPrice: PropTypes.string,
  unitType: PropTypes.string,
  unitsSoldAs: PropTypes.string,
  hasLegends: PropTypes.string,
  traded: PropTypes.string,
  restricted: PropTypes.bool,
  neverTransferToNonAccredited: PropTypes.string,
  firstRightOfRefusal: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
})

export const OfferingSubscriptionAgreement = PropTypes.shape({
  draftContent: PropTypes.string,
  issuerName: PropTypes.string,
  issuerTitle: PropTypes.string,
  issuerEmail: PropTypes.string,
  issuerLiteral: PropTypes.string,
})

export const FAShortCodes = PropTypes.shape({
  value: PropTypes.string,
  createdAt: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const SubscriptionVariables = PropTypes.arrayOf(
  PropTypes.shape({
    createdAt: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.arrayOf(FAShortCodes),
  }),
)

export const OFFERING_SIGNATURE_STATUS = {
  ADDED: 'added',
  SIGNED: 'signed',
  SENT: 'sent',
}

export const ASSOCIATED_OFFERING_SIGNATURE_STATUS = {
  SENT: 'sent',
  NOT_SENT: 'not_sent',
  SIGNED: 'signed',
  SENDING_ERROR: 'sending_error',
  NOT_REQUESTED: 'not_requested',
}

export const LABELS_ASSOCIATED_OFFERING_SIGNATURE_STATUS = {
  [ASSOCIATED_OFFERING_SIGNATURE_STATUS.SENT]: 'Sent',
  [ASSOCIATED_OFFERING_SIGNATURE_STATUS.NOT_SENT]: 'Not Sent',
  [ASSOCIATED_OFFERING_SIGNATURE_STATUS.SIGNED]: 'Signed',
  [ASSOCIATED_OFFERING_SIGNATURE_STATUS.SENDING_ERROR]: 'Sending error',
  [ASSOCIATED_OFFERING_SIGNATURE_STATUS.NOT_REQUESTED]: 'Not Requested',
}

export const OfferingSignature = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  shortcode: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  userRequired: PropTypes.bool,
  weight: PropTypes.number.isRequired,
  signedAt: PropTypes.string,
  signedName: PropTypes.string,
  signedRole: PropTypes.string,
  lastSignatureRequest: PropTypes.oneOfType([
    PropTypes.shape({
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      status: PropTypes.oneOf(Object.keys(OFFERING_SIGNATURE_STATUS)),
      uuid: PropTypes.string,
    }),
    PropTypes.instanceOf(Object),
  ]),
  appObjectType: PropTypes.oneOf(['offering', 'investment']),
  appObjectUuid: PropTypes.string.isRequired,
})

export const UnitClass = PropTypes.shape({
  name: PropTypes.string.isRequired,
  offeringUuid: PropTypes.string,
  enabled: PropTypes.bool,
  usedInInvestments: PropTypes.bool,
  uuid: PropTypes.string,
})

// Offering Section
// -----------------------------------------------------------------------------
export const OFFERING_SECTION_BLOCK = {
  HEADER: 'header',
  INVEST: 'invest',
  OVERVIEW: 'overview',
  HIGHLIGHTS: 'highlights',
  SIDEBAR: 'sidebar',
  SECTIONS: 'sections',
  PDF_SECTIONS: 'pdfSections',
  FOOTER: 'footer',
  DATA_ROOM: 'data_room',
}

// Offering Section Due Diligence
// -----------------------------------------------------------------------------
export const OFFERING_SECTION_DUE_DILIGENCE_BLOCK = {
  DUE_DILIGENCE: 'due_diligence',
  DUE_DILIGENCE_DATA_ROOM: 'due_diligence_data_room',
}

// Offering Section Due Diligence
// -----------------------------------------------------------------------------
export const OFFERING_SECTION_SECOND_PAGE = {
  SECOND_PAGE_SECTIONS: 'second_page_sections',
  SECOND_PAGE_DATA_ROOM: 'second_page_data_room',
}

export const OFFERING_SECTION_THIRD_PAGE = {
  THIRD_PAGE_SECTIONS: 'third_page_sections',
  THIRD_PAGE_DATA_ROOM: 'third_page_data_room',
}

export const OFFERING_SECTION_VISIBILITY = {
  AVAILABLE: 'full_availability',
  REQUEST_ACCESS_NEEDED: 'request_access_needed',
  PRIVATE: 'private',
}
export const OfferingSection = PropTypes.shape({
  block: PropTypes.oneOf(
    Object.values({ ...OFFERING_SECTION_BLOCK, ...OFFERING_SECTION_DUE_DILIGENCE_BLOCK }),
  ),
  content: PropTypes.string,
  contentVisible: PropTypes.bool,
  enabled: PropTypes.bool,
  enableTableOfContent: PropTypes.bool,
  showReview: PropTypes.bool,
  lockedMessage: PropTypes.string,
  private: PropTypes.bool,
  pdfCheckHideBookmark: PropTypes.string,
  pdfNavigation: PropTypes.array,
  pdfSectionUrl: PropTypes.string,
  reviewedByCurrentUser: PropTypes.bool,
  reviewedByCurrentUserDate: PropTypes.string,
  roles: PropTypes.string,
  title: PropTypes.string,
  uuid: PropTypes.string,
  weight: PropTypes.number,
})

// Access Request
// -----------------------------------------------------------------------------
export const ACCESS_REQUEST_STATUS = {
  INITIATED: 'initiated',
  APPROVED: 'approved',
  DENIED: 'denied',
}

// Offering Document
// -----------------------------------------------------------------------------
export const OfferingDocument = PropTypes.shape({
  description: PropTypes.string,
  url: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  weight: PropTypes.number,
  dependsOnUuid: PropTypes.string,
  useExternalFileLink: PropTypes.bool,
  externalFileLink: PropTypes.string,
  // createdAt: PropTypes.string,
  // updatedAt: PropTypes.string,
  // offeringUuid: PropTypes.string,
  // fileUuid: PropTypes.string,
  // public: PropTypes.bool,
})

export const OFFERING_DOCUMENT_FOLDER_TYPE = 'folder'
export const OFFERING_DOCUMENT_SUB_FOLDER_TYPE = 'sub-folder'
export const OFFERING_DOCUMENT_FILE_TYPE = 'file'

// Investor
// -----------------------------------------------------------------------------

export const INVESTOR_TYPE = {
  PERSON: 'person',
  COMPANY: 'company',
  TRUST: 'trust',
  IRA: 'ira',
}
export const INVESTOR_TYPE_OPTIONS = {
  [INVESTOR_TYPE.PERSON]: 'Person',
  [INVESTOR_TYPE.COMPANY]: 'Company',
  [INVESTOR_TYPE.TRUST]: 'Trust',
  [INVESTOR_TYPE.IRA]: 'IRA',
}

export const INVESTOR_TYPE_OPTIONS_OFFERING_ONBOARDING = {
  [INVESTOR_TYPE.PERSON]: 'Person',
  [INVESTOR_TYPE.COMPANY]: 'Company',
  [INVESTOR_TYPE.TRUST]: 'Trust',
}

export const IS_JOINT = {
  NO: 0,
  YES: 1,
}

export const IS_JOINT_OPTIONS = [
  { value: IS_JOINT.NO, label: 'No' },
  { value: IS_JOINT.YES, label: 'Yes' },
]

export const JOINT_TYPE = {
  NONE: null,
  SURVIRSHOP: 1,
  COMMON: 2,
  ENTIRETY: 3,
  SEPARATE: 4,
  COMMUNITY_SURVIRSHOP: 5,
  COMMUNITY: 6,
}

export const JOINT_TYPES = [
  JOINT_TYPE.SURVIRSHOP,
  JOINT_TYPE.COMMON,
  JOINT_TYPE.ENTIRETY,
  JOINT_TYPE.SEPARATE,
  JOINT_TYPE.COMMUNITY_SURVIRSHOP,
  JOINT_TYPE.COMMUNITY,
]

export const JOINT_TYPE_LABELS = {
  [JOINT_TYPE.SURVIRSHOP]: 'Joint Tenants with Right of Survivorship',
  [JOINT_TYPE.COMMON]: 'Joint Tenants in Common',
  [JOINT_TYPE.ENTIRETY]: 'Tenants by the Entirety',
  [JOINT_TYPE.SEPARATE]: 'A Married Person, as my Sole and Separate Property',
  [JOINT_TYPE.COMMUNITY_SURVIRSHOP]:
    'Husband and Wife, as Community Property with Rights of Survivorship',
  [JOINT_TYPE.COMMUNITY]: 'Husband and Wife, as Community Property',
}

export const JOINT_TYPE_LABELS_SHORTEN = {
  [JOINT_TYPE.SURVIRSHOP]: 'JTwRoS',
  [JOINT_TYPE.COMMON]: 'JTiC',
  [JOINT_TYPE.ENTIRETY]: 'TbtE',
  [JOINT_TYPE.SEPARATE]: 'A Married Person, as my Sole and Separate Property',
  [JOINT_TYPE.COMMUNITY_SURVIRSHOP]:
    'Husband and Wife, as Community Property with Rights of Survivorship',
  [JOINT_TYPE.COMMUNITY]: 'Husband and Wife, as Community Property',
}

export const JOINT_TYPE_OPTIONS = [
  { value: JOINT_TYPE.SURVIRSHOP, label: JOINT_TYPE_LABELS[JOINT_TYPE.SURVIRSHOP] },
  { value: JOINT_TYPE.COMMON, label: JOINT_TYPE_LABELS[JOINT_TYPE.COMMON] },
  { value: JOINT_TYPE.ENTIRETY, label: JOINT_TYPE_LABELS[JOINT_TYPE.ENTIRETY] },
  { value: JOINT_TYPE.SEPARATE, label: JOINT_TYPE_LABELS[JOINT_TYPE.SEPARATE] },
  {
    value: JOINT_TYPE.COMMUNITY_SURVIRSHOP,
    label: JOINT_TYPE_LABELS[JOINT_TYPE.COMMUNITY_SURVIRSHOP],
  },
  { value: JOINT_TYPE.COMMUNITY, label: JOINT_TYPE_LABELS[JOINT_TYPE.COMMUNITY] },
]

export const INVESTOR_JOINT_TYPE_FILTERS = {
  'joint-1': JOINT_TYPE_LABELS_SHORTEN[JOINT_TYPE.SURVIRSHOP],
  'joint-2': JOINT_TYPE_LABELS_SHORTEN[JOINT_TYPE.COMMON],
  'joint-3': JOINT_TYPE_LABELS_SHORTEN[JOINT_TYPE.ENTIRETY],
  'joint-4': JOINT_TYPE_LABELS_SHORTEN[JOINT_TYPE.SEPARATE],
  'joint-5': JOINT_TYPE_LABELS_SHORTEN[JOINT_TYPE.COMMUNITY_SURVIRSHOP],
  'joint-6': JOINT_TYPE_LABELS_SHORTEN[JOINT_TYPE.COMMUNITY],
}

export const EXTRA_TYPE_FOR_JOINT_FILTER = 'joint'

export const INVESTOR_FILTER_TYPE_OPTIONS = {
  ...INVESTOR_TYPE_OPTIONS,
  ...INVESTOR_JOINT_TYPE_FILTERS,
}

export const INVESTOR_ACCREDITED = 'accredited'
export const INVESTOR_NON_ACCREDITED = 'non_accredited'
export const INVESTOR_ACCREDITED_OPTIONS = {
  [INVESTOR_ACCREDITED]: 'Yes',
  [INVESTOR_NON_ACCREDITED]: 'No',
}

export const US_RESIDENT_YES = 'US'
export const US_RESIDENT_NO = 'non_us'

export const US_RESIDENT_OPTIONS = {
  [US_RESIDENT_YES]: 'Yes',
  [US_RESIDENT_NO]: 'No',
}

export const ACCREDITATION_TYPE_NON_ACCREDITED = 'non_accredited'

export const PERSONAL_ACCREDITATION_TYPE_OPTIONS = {
  individual_net_worth:
    "I have an individual net worth, or joint net worth with my spouse, that exceeds $1 million including any IRA's, 401K's and other retirement accounts, but excluding the net value of my primary residence.",
  individual_income:
    'I am an individual with income of over $200,000 in each of the last two years, or joint income with my spouse exceeding $300,000 in those years, and I reasonably expect at least the same this year.',
  certified_individual_a:
    'I am an individual holding in good standing any of the general securities representative license (Series 7), the investment adviser representative license (Series 65), or the private securities offerings representative license (Series 82).',
  certified_individual_b:
    'I am an SEC-registered broker-dealer, SEC- or state-registered investment adviser, or exempt reporting adviser.',
  knowledgable_employee_a:
    'I am a knowledgeable employee, as defined in rule 3c-5(a)(4) under the Investment Company Act, of the issuer of securities where that issuer is a 3(c)(1) or 3(c)(7) private fund.',
  knowledgable_employee_b:
    'I am a director, executive officer, or general partner of the company selling the securities, or any director, executive officer, or general partner of a general partner of that company.',
}

export const COMPANY_ACCREDITATION_TYPE_OPTIONS = {
  business:
    'We are a business, revocable trust or other non-individual entity in which all the equity owners or grantors/settlors are accredited investors.',
  bank: 'We are a bank, insurance company, pension fund, or other registered investment company with assets exceeding $5 million.',
  corporation:
    'We are a corporation, partnership, or charitable organization with at least $5 million in assets.',
  employee_benefit_plan:
    'We are an employee benefit plan, within the meaning of the Employee Retirement Income Security Act, if a bank, insurance company, or registered investment adviser makes the investment decisions, or if the plan has total assets in excess of $5 million.',
  trust:
    'We are a trust with assets in excess of $5 million, not specifically formed to acquire the securities offered, whose purchases a sophisticated investor makes.',
  family_office:
    'A family office and its family clients if the family office has assets under management in excess of $5 million and whose prospective investments are directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment.',
}

export const ISSUER_COMPANY_ACCREDITATION_TYPE_OPTIONS = {
  llc: 'LLC',
  c_corp: 'C-Corp',
  s_corp: 'S-Corp',
  llp: 'LLP',
  'sole-prop': 'Sole Proprietorship',
  // 'non-us-business': 'Non-US Business',
}

const ACCREDITATION_TYPES = [
  ...Object.keys(COMPANY_ACCREDITATION_TYPE_OPTIONS),
  ...Object.keys(PERSONAL_ACCREDITATION_TYPE_OPTIONS),
  ACCREDITATION_TYPE_NON_ACCREDITED,
]

export const CONFIRM_EMAIL_ALERT_KEY = 'email_confirm_alert'

export const BENEFICIAL_OWNER_TYPE_OPTIONS = {
  independently:
    'As Beneficial Owner and/or Control Person(s), I may act independently as provided in the Trust or Operating Agreement.',
  majority:
    'The Beneficial Owner(s) and/or Control Person(s) listed above may act as a majority as provided in the Trust or Operating Agreement.',
  unanimously:
    'The Beneficial Owner(s) and/or Control Person(s) listed above must act unanimously as provided in the Trust or Operating Agreement, and the authorization of all Beneficial Owners is required.',
}

export const Investor = PropTypes.shape({
  accredited: PropTypes.bool,
  accreditationType: PropTypes.oneOf(ACCREDITATION_TYPES),
  city: PropTypes.string,
  companyName: PropTypes.string,
  completed: PropTypes.bool,
  country: PropTypes.string,
  createdAt: PropTypes.string,
  dateOfBirth: PropTypes.string,
  email: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  isWithAssociatedPersons: PropTypes.bool,
  registeredName: PropTypes.string,
  paymentMethods: PropTypes.object,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
  regionFormedIn: PropTypes.string,
  stateOfLegalDomicle: PropTypes.string,
  stateOfSale: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  legalAddressYears: PropTypes.number,
  taxIdNumber: PropTypes.string,
  taxIdNumberType: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INVESTOR_TYPE)),
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  alternatePhone: PropTypes.string,
  alternateRegion: PropTypes.string,
  alternateCity: PropTypes.string,
  alternateStreet1: PropTypes.string,
  alternateStreet2: PropTypes.string,
  alternatePostalCode: PropTypes.string,
  hasAutoOwnerWith25Percent: PropTypes.bool,
  hasManualOwnerWith25Percent: PropTypes.bool,
  beneficialOwnerType: PropTypes.oneOf(Object.keys(BENEFICIAL_OWNER_TYPE_OPTIONS)),

  //joint
  isJoint: PropTypes.bool,
  jointAlternateCity: PropTypes.string,
  jointAlternateEmail: PropTypes.string,
  jointAlternatePhone: PropTypes.string,
  jointAlternatePostalCode: PropTypes.string,
  jointAlternateRegion: PropTypes.string,
  jointAlternateStreet1: PropTypes.string,
  jointAlternateStreet2: PropTypes.string,
  jointCity: PropTypes.string,
  jointCountry: PropTypes.string,
  jointDateOfBirth: PropTypes.string,
  jointEmail: PropTypes.string,
  jointFirstName: PropTypes.string,
  jointLastName: PropTypes.string,
  jointLegalAddressYears: PropTypes.number,
  jointPhone: PropTypes.string,
  jointPostalCode: PropTypes.string,
  jointRegion: PropTypes.string,
  jointStreet1: PropTypes.string,
  jointStreet2: PropTypes.string,
  jointTaxIdNumber: PropTypes.string,
  jointType: PropTypes.number,
  jointUuid: PropTypes.string,

  //investor type IRA only
  investorDetailsFull: PropTypes.bool,
})

export const AssociatedPerson = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  createdAt: PropTypes.string,
  dateOfBirth: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isPrimary: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  legalAddressYears: PropTypes.number,
  taxIdNumber: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  alternateEmail: PropTypes.string,
  alternatePhone: PropTypes.string,
  alternateRegion: PropTypes.string,
  alternateCity: PropTypes.string,
  alternateStreet1: PropTypes.string,
  alternateStreet2: PropTypes.string,
  alternatePostalCode: PropTypes.string,
  isNeedToSignAgreement: PropTypes.bool,
})

export const Issuer = PropTypes.shape({
  name: PropTypes.string,
  country: PropTypes.string,
  companyType: PropTypes.string,
  taxIdNumber: PropTypes.string,
  regionFormedIn: PropTypes.string,
  yearFounded: PropTypes.string,
  contactName: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postalCode: PropTypes.string,
})

export const OfferingAssociatedPersonTitlesOptions = {
  affiliated: 'Affiliated',
  executive: 'Executive',
  director: 'Director',
  share_holder: 'Share holder',
  promoter: 'Promoter',
  solicitor: 'Solicitor',
  manager: 'Manager',
}
export const OfferingAssociatedPerson = PropTypes.shape({
  type: PropTypes.oneOf(Object.keys(INVESTOR_TYPE_OPTIONS)),
  name: PropTypes.string,
  country: PropTypes.string,
  taxIdNumber: PropTypes.string,
  dateOfBirth: PropTypes.string,
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postalCode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  contactName: PropTypes.string,
  regionFormedIn: PropTypes.string,
  titles: PropTypes.string,
})

export const OWNER = 'Owner'
export const MANAGER = 'Manager'
export const TRUSTEE = 'Trustee'

export const AssociatedPersonRolesOptions = {
  [OWNER]: OWNER,
  [MANAGER]: MANAGER,
  [TRUSTEE]: TRUSTEE,
}

export const DOCUMENT_TYPE = {
  SIGNED_OPERATING_AGREEMENT: 'Signed operating agreement',
  ARTICLES_OF_INCORPORATION: 'Articles of incorporation',
  CERTIFICATE_OF_FORMATION: 'Certificate of formation',
  BY_LAWS__FOR_THIS_INVESTOR: 'By-laws that list the associated person for this investor',
  PROOF_OF_ADDRESS_LAST_90_DAYS: 'Proof of address dated within the last 90 days',
  DRIVERS_LICENSE: 'Drivers license',
  TRUST_CERTIFICATION: 'Trust certification',
  OTHER: 'Other',
  K1: 'K-1',
  SUBSCRIPTION_AGREEMENT: 'sa',
  FORM_1065: 'Form-1065',
  FORM_8283: 'Form-8283',
  FORM_8886: 'Form-8886',
  MEMO: 'Memo',
  LETTER: 'Letter',
  COI_LETTER: 'COI Letter',
  TRANSFER: 'Transfer',
  Baseline_Report: 'Baseline-Report',
  Deed: 'Deed',
  Qualified_Appraisal: 'Qualified-Appraisal',
}

export const INVESTMENT_DOCUMENT_TYPES = [
  DOCUMENT_TYPE.K1,
  DOCUMENT_TYPE.SUBSCRIPTION_AGREEMENT,
  DOCUMENT_TYPE.FORM_1065,
  DOCUMENT_TYPE.FORM_8283,
  DOCUMENT_TYPE.FORM_8886,
  DOCUMENT_TYPE.MEMO,
  DOCUMENT_TYPE.LETTER,
  DOCUMENT_TYPE.COI_LETTER,
  DOCUMENT_TYPE.TRANSFER,
  DOCUMENT_TYPE.Baseline_Report,
  DOCUMENT_TYPE.Deed,
  DOCUMENT_TYPE.Qualified_Appraisal,
]

export const PERSONAL_DOCUMENT_TYPES = [
  DOCUMENT_TYPE.SIGNED_OPERATING_AGREEMENT,
  DOCUMENT_TYPE.ARTICLES_OF_INCORPORATION,
  DOCUMENT_TYPE.CERTIFICATE_OF_FORMATION,
  DOCUMENT_TYPE.BY_LAWS__FOR_THIS_INVESTOR,
  DOCUMENT_TYPE.PROOF_OF_ADDRESS_LAST_90_DAYS,
  DOCUMENT_TYPE.DRIVERS_LICENSE,
  DOCUMENT_TYPE.TRUST_CERTIFICATION,
  DOCUMENT_TYPE.OTHER,
]

export const InvestmentDocumentTypeOptions = {
  [DOCUMENT_TYPE.K1]: 'K-1',
  [DOCUMENT_TYPE.SUBSCRIPTION_AGREEMENT]: 'SAs',
  [DOCUMENT_TYPE.FORM_1065]: 'Form-1065',
  [DOCUMENT_TYPE.FORM_8283]: 'Form-8283',
  [DOCUMENT_TYPE.FORM_8886]: 'Form-8886',
  [DOCUMENT_TYPE.MEMO]: 'Memo',
  [DOCUMENT_TYPE.Baseline_Report]: 'Baseline-Report',
  [DOCUMENT_TYPE.Deed]: 'Deed',
  [DOCUMENT_TYPE.Qualified_Appraisal]: 'Qualified-Appraisal',
}

export const PersonalDocumentTypeOptions = {
  [DOCUMENT_TYPE.SIGNED_OPERATING_AGREEMENT]: DOCUMENT_TYPE.SIGNED_OPERATING_AGREEMENT,
  [DOCUMENT_TYPE.ARTICLES_OF_INCORPORATION]: DOCUMENT_TYPE.ARTICLES_OF_INCORPORATION,
  [DOCUMENT_TYPE.CERTIFICATE_OF_FORMATION]: DOCUMENT_TYPE.CERTIFICATE_OF_FORMATION,
  [DOCUMENT_TYPE.BY_LAWS__FOR_THIS_INVESTOR]: DOCUMENT_TYPE.BY_LAWS__FOR_THIS_INVESTOR,
  [DOCUMENT_TYPE.PROOF_OF_ADDRESS_LAST_90_DAYS]: DOCUMENT_TYPE.PROOF_OF_ADDRESS_LAST_90_DAYS,
  [DOCUMENT_TYPE.DRIVERS_LICENSE]: DOCUMENT_TYPE.DRIVERS_LICENSE,
  [DOCUMENT_TYPE.TRUST_CERTIFICATION]: DOCUMENT_TYPE.TRUST_CERTIFICATION,
  [DOCUMENT_TYPE.OTHER]: DOCUMENT_TYPE.OTHER,
}

export const DashboardInvestorTypeOptions = {
  [DOCUMENT_TYPE.SIGNED_OPERATING_AGREEMENT]: DOCUMENT_TYPE.SIGNED_OPERATING_AGREEMENT,
  [DOCUMENT_TYPE.ARTICLES_OF_INCORPORATION]: DOCUMENT_TYPE.ARTICLES_OF_INCORPORATION,
  [DOCUMENT_TYPE.CERTIFICATE_OF_FORMATION]: DOCUMENT_TYPE.CERTIFICATE_OF_FORMATION,
  [DOCUMENT_TYPE.BY_LAWS__FOR_THIS_INVESTOR]: DOCUMENT_TYPE.BY_LAWS__FOR_THIS_INVESTOR,
  [DOCUMENT_TYPE.PROOF_OF_ADDRESS_LAST_90_DAYS]: DOCUMENT_TYPE.PROOF_OF_ADDRESS_LAST_90_DAYS,
  [DOCUMENT_TYPE.DRIVERS_LICENSE]: DOCUMENT_TYPE.DRIVERS_LICENSE,
  [DOCUMENT_TYPE.TRUST_CERTIFICATION]: DOCUMENT_TYPE.TRUST_CERTIFICATION,
  [DOCUMENT_TYPE.K1]: DOCUMENT_TYPE.K1,
  [DOCUMENT_TYPE.FORM_1065]: DOCUMENT_TYPE.FORM_1065,
  [DOCUMENT_TYPE.FORM_8283]: DOCUMENT_TYPE.FORM_8283,
  [DOCUMENT_TYPE.FORM_8886]: DOCUMENT_TYPE.FORM_8886,
  [DOCUMENT_TYPE.MEMO]: DOCUMENT_TYPE.MEMO,
  [DOCUMENT_TYPE.LETTER]: DOCUMENT_TYPE.LETTER,
  [DOCUMENT_TYPE.COI_LETTER]: DOCUMENT_TYPE.COI_LETTER,
  [DOCUMENT_TYPE.TRANSFER]: DOCUMENT_TYPE.TRANSFER,
  [DOCUMENT_TYPE.Baseline_Report]: DOCUMENT_TYPE.Baseline_Report,
  [DOCUMENT_TYPE.Deed]: DOCUMENT_TYPE.Deed,
  [DOCUMENT_TYPE.Qualified_Appraisal]: DOCUMENT_TYPE.Qualified_Appraisal,
  [DOCUMENT_TYPE.OTHER]: DOCUMENT_TYPE.OTHER,
}

export const DocumentTypeOptions = {
  ...InvestmentDocumentTypeOptions,
  ...PersonalDocumentTypeOptions,
}

export const OFFERING_UPDATE_FILE_TYPE = {
  AUDIT: 'audit',
  FINANCIAL_STATEMENT: 'financial_statement',
  FUND_REPORT: 'fund_reports',
  OTHER: 'other',
}

export const OfferingUpdateDocumentTypeOptions = {
  [OFFERING_UPDATE_FILE_TYPE.AUDIT]: 'Audit',
  [OFFERING_UPDATE_FILE_TYPE.FINANCIAL_STATEMENT]: 'Financial Statement',
  [OFFERING_UPDATE_FILE_TYPE.FUND_REPORT]: 'Fund Reports',
  [OFFERING_UPDATE_FILE_TYPE.OTHER]: 'Other',
}

export const OfferingUpdateDocument = PropTypes.shape({
  url: PropTypes.string,
  title: PropTypes.string,
  weight: PropTypes.number,
  type: PropTypes.string,
  isVisible: PropTypes.bool,
})

export const OfferingUpdatePost = PropTypes.shape({
  title: PropTypes.string,
  content: PropTypes.string,
  weight: PropTypes.number,
  isVisible: PropTypes.bool,
})
export const InvestorAccreditationRequestItemRejected = 'rejected'
export const InvestorAccreditationRequestItemConfirmed = 'confirmed'
export const InvestorAccreditationRequestItemNew = 'new'

const InvestorAccreditationRequestItemStatuses = [
  InvestorAccreditationRequestItemRejected,
  InvestorAccreditationRequestItemConfirmed,
  InvestorAccreditationRequestItemNew,
]

export const InvestorAccreditationRequestItem = PropTypes.shape({
  fullname: PropTypes.string,
  email: PropTypes.string,
  status: PropTypes.oneOf(InvestorAccreditationRequestItemStatuses),
  sentAt: PropTypes.string,
})

export const InvestorAccreditationRequestList = PropTypes.arrayOf(InvestorAccreditationRequestItem)

export const limitedLiabilityCompany = 'llc'
export const limitedPartnership = 'lp'
export const c_corporation = 'corporation'
export const s_corporation = 'scorporation'
export const revocableTrust = 'rtrust'
export const irrevocableTrust = 'itrust'
export const otherTypeOwnership = 'other'
export const singleMemberOrDisregardedEntity = 'smde'
export const taxClasificationPartnership = 'p'
export const TaxClasificationCcorporation = 'ccorporation'

export const limitedLiabilityCompanyLabel = 'Limited Liability Company (LLC)'
export const limitedPartnershipLabel = 'Partnership'
export const c_corporationLabel = 'C Corporation'
export const s_corporationLabel = 'S Corporation'
export const revocableTrustLabel = 'Revocable Trust'
export const irrevocableTrustLabel = 'Irrevocable Trust'
export const otherTypeOwnershipLabel = 'Other'
export const singleMemberOrDisregardedEntityLabel = 'single member/disregarded entity'

export const InvestorCompanyOwnershipTypes = {
  [limitedLiabilityCompany]: limitedLiabilityCompanyLabel,
  [limitedPartnership]: limitedPartnershipLabel,
  [c_corporation]: c_corporationLabel,
  [s_corporation]: s_corporationLabel,
  [otherTypeOwnership]: otherTypeOwnershipLabel,
}

export const InvestorCompanyTaxClasificationTypes = {
  [singleMemberOrDisregardedEntity]: singleMemberOrDisregardedEntityLabel,
  [s_corporation]: s_corporationLabel,
  [TaxClasificationCcorporation]: c_corporationLabel,
  [taxClasificationPartnership]: limitedPartnershipLabel,
}

export const InvestorTrustOwnershipTypes = {
  [revocableTrust]: revocableTrustLabel,
  [irrevocableTrust]: irrevocableTrustLabel,
  [otherTypeOwnership]: otherTypeOwnershipLabel,
}

export const SSN = 'ssn'
export const EIN = 'ein'

export const InvestorEinSsnChoices = {
  [EIN]: 'EIN',
  [SSN]: 'SSN',
}

export const InvestorDocument = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string,
  uuid: PropTypes.string,
  investor: Investor,
})

export const InvestorSharedDocument = PropTypes.shape({
  investorFile: InvestorDocument,
  sharedByUser: User,
  sharedToUser: User,
  uuid: PropTypes.string,
})

export const WireInstruction = PropTypes.shape({
  accountNumber: PropTypes.string,
  bankAddress: PropTypes.string,
  bankName: PropTypes.string,
  bankPhone: PropTypes.string,
  beneficiaryAddress: PropTypes.string,
  beneficiaryName: PropTypes.string,
  routingNumber: PropTypes.string,
  swiftCode: PropTypes.string,
  uuid: PropTypes.string,
})

export const InvestorListItemProps = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  type: PropTypes.string.isRequired,
  totalInvestments: PropTypes.number.isRequired,
  pendingInvestments: PropTypes.number.isRequired,
  draftInvestments: PropTypes.number.isRequired,
  lastTransactionDate: PropTypes.string,
}
export const InvestorListItem = PropTypes.shape(InvestorListItemProps)

const AWAITING_SIGNATURES = 'Awaiting Signatures'
const SUB_DOC_COMPLETE = 'SubDoc Complete'
const IN_REVIEW_PENDING = 'In Review - Pending'
const APPROVED = 'Approved'
export const DENIED = 'Denied'
export const ACCEPTED_AND_INVESTED = 'Accepted and Invested'
const TRANSFERRED_FUNDS = 'Transferred Funds'

export const INVESTMENT_STATUS = {
  DRAFT: 'draft',
  NOT_RECEIVED: 'not_received',
  RECEIVED: 'received',
  INVESTED: 'invested',
  CANCELED: 'cancelled',
  VOIDED: 'voided',
  [AWAITING_SIGNATURES]: AWAITING_SIGNATURES,
  [SUB_DOC_COMPLETE]: SUB_DOC_COMPLETE,
  [IN_REVIEW_PENDING]: IN_REVIEW_PENDING,
  [APPROVED]: APPROVED,
  [DENIED]: DENIED,
  [ACCEPTED_AND_INVESTED]: ACCEPTED_AND_INVESTED,
  [TRANSFERRED_FUNDS]: TRANSFERRED_FUNDS,
}

const IN_REVIEW_506C = 'In Review'
const APPROVED_506C = 'Approved'
const DENIED_506C = 'Denied'

export const INVESTMENT_506C_STATUS = {
  [IN_REVIEW_506C]: IN_REVIEW_506C,
  [APPROVED_506C]: APPROVED_506C,
  [DENIED_506C]: DENIED_506C,
}

export const FA_INVESTMENT_STATUS = {
  ...INVESTMENT_STATUS,
  REFUNDED: 'refunded',
}

export const INVESTMENT_STATUS_LABELS = {
  DRAFT: 'Draft',
  NOT_RECEIVED: 'Not Received (Legacy)',
  RECEIVED: 'Received (Legacy)',
  INVESTED: 'Invested (Legacy)',
  CANCELED: 'Cancelled',
  VOIDED: 'Voided (Legacy)',
  REFUNDED: 'Refunded (Legacy)',
  [AWAITING_SIGNATURES]: AWAITING_SIGNATURES,
  [SUB_DOC_COMPLETE]: 'Awaiting Review - Signatures Complete',
  [IN_REVIEW_PENDING]: 'In Review - Pending',
  [APPROVED]: APPROVED,
  [DENIED]: DENIED,
  [ACCEPTED_AND_INVESTED]: 'Accepted & Invested',
  [TRANSFERRED_FUNDS]: TRANSFERRED_FUNDS,
}
export const ADVISOR_INVESTMENT_STATUS_LABELS = {
  DRAFT: 'Draft',
  DRAFT_SENT_TO_CLIENT: 'Sent to Client',
  NOT_RECEIVED: 'Not Received (Legacy)',
  RECEIVED: 'Received (Legacy)',
  INVESTED: 'Invested (Legacy)',
  CANCELED: 'Cancelled',
  VOIDED: 'Voided (Legacy)',
  REFUNDED: 'Refunded (Legacy)',
  [AWAITING_SIGNATURES]: AWAITING_SIGNATURES,
  [SUB_DOC_COMPLETE]: 'Awaiting Review - Signatures Complete',
  [IN_REVIEW_PENDING]: 'In Review - Pending',
  [APPROVED]: APPROVED,
  [DENIED]: DENIED,
  [ACCEPTED_AND_INVESTED]: 'Accepted & Invested',
  [TRANSFERRED_FUNDS]: TRANSFERRED_FUNDS,
}

export const INVESTMENT_STATUS_OPTIONS = {
  [INVESTMENT_STATUS.DRAFT]: INVESTMENT_STATUS_LABELS.DRAFT,
  [AWAITING_SIGNATURES]: INVESTMENT_STATUS_LABELS[AWAITING_SIGNATURES],
  [SUB_DOC_COMPLETE]: INVESTMENT_STATUS_LABELS[SUB_DOC_COMPLETE],
  [IN_REVIEW_PENDING]: INVESTMENT_STATUS_LABELS[IN_REVIEW_PENDING],
  [APPROVED]: INVESTMENT_STATUS_LABELS[APPROVED],
  [DENIED]: INVESTMENT_STATUS_LABELS[DENIED],
  [ACCEPTED_AND_INVESTED]: INVESTMENT_STATUS_LABELS[ACCEPTED_AND_INVESTED],
  [INVESTMENT_STATUS.NOT_RECEIVED]: INVESTMENT_STATUS_LABELS.NOT_RECEIVED,
  [INVESTMENT_STATUS.RECEIVED]: INVESTMENT_STATUS_LABELS.RECEIVED,
  [INVESTMENT_STATUS.INVESTED]: INVESTMENT_STATUS_LABELS.INVESTED,
  [INVESTMENT_STATUS.CANCELED]: INVESTMENT_STATUS_LABELS.CANCELED,
  [INVESTMENT_STATUS.VOIDED]: INVESTMENT_STATUS_LABELS.VOIDED,
  [TRANSFERRED_FUNDS]: INVESTMENT_STATUS_LABELS[TRANSFERRED_FUNDS],
}

export const ADVISOR_INVESTMENT_STATUS_OPTIONS = ({ clientSignatureRequest }) => ({
  [INVESTMENT_STATUS.DRAFT]: clientSignatureRequest
    ? ADVISOR_INVESTMENT_STATUS_LABELS.DRAFT_SENT_TO_CLIENT
    : ADVISOR_INVESTMENT_STATUS_LABELS.DRAFT,
  [AWAITING_SIGNATURES]: INVESTMENT_STATUS_LABELS[AWAITING_SIGNATURES],
  [SUB_DOC_COMPLETE]: INVESTMENT_STATUS_LABELS[SUB_DOC_COMPLETE],
  [IN_REVIEW_PENDING]: INVESTMENT_STATUS_LABELS[IN_REVIEW_PENDING],
  [APPROVED]: INVESTMENT_STATUS_LABELS[APPROVED],
  [DENIED]: INVESTMENT_STATUS_LABELS[DENIED],
  [ACCEPTED_AND_INVESTED]: INVESTMENT_STATUS_LABELS[ACCEPTED_AND_INVESTED],
  [INVESTMENT_STATUS.NOT_RECEIVED]: ADVISOR_INVESTMENT_STATUS_LABELS.NOT_RECEIVED,
  [INVESTMENT_STATUS.RECEIVED]: ADVISOR_INVESTMENT_STATUS_LABELS.RECEIVED,
  [INVESTMENT_STATUS.INVESTED]: ADVISOR_INVESTMENT_STATUS_LABELS.INVESTED,
  [INVESTMENT_STATUS.CANCELED]: ADVISOR_INVESTMENT_STATUS_LABELS.CANCELED,
  [INVESTMENT_STATUS.VOIDED]: ADVISOR_INVESTMENT_STATUS_LABELS.VOIDED,
  [TRANSFERRED_FUNDS]: INVESTMENT_STATUS_LABELS[TRANSFERRED_FUNDS],
})

export const DASHBOARD_INVESTMENT_STATUS_OPTIONS = {
  [INVESTMENT_STATUS.DRAFT]: INVESTMENT_STATUS_LABELS.DRAFT,
  [AWAITING_SIGNATURES]: INVESTMENT_STATUS_LABELS[AWAITING_SIGNATURES],
  [SUB_DOC_COMPLETE]: INVESTMENT_STATUS_LABELS[SUB_DOC_COMPLETE],
  [IN_REVIEW_PENDING]: INVESTMENT_STATUS_LABELS[IN_REVIEW_PENDING],
  [APPROVED]: INVESTMENT_STATUS_LABELS[APPROVED],
  [DENIED]: INVESTMENT_STATUS_LABELS[DENIED],
  [ACCEPTED_AND_INVESTED]: INVESTMENT_STATUS_LABELS[ACCEPTED_AND_INVESTED],
  [FA_INVESTMENT_STATUS.NOT_RECEIVED]: INVESTMENT_STATUS_LABELS.NOT_RECEIVED,
  [FA_INVESTMENT_STATUS.RECEIVED]: INVESTMENT_STATUS_LABELS.RECEIVED,
  [FA_INVESTMENT_STATUS.INVESTED]: INVESTMENT_STATUS_LABELS.INVESTED,
  [FA_INVESTMENT_STATUS.CANCELED]: INVESTMENT_STATUS_LABELS.CANCELED,
  [FA_INVESTMENT_STATUS.VOIDED]: INVESTMENT_STATUS_LABELS.VOIDED,
  [FA_INVESTMENT_STATUS.REFUNDED]: INVESTMENT_STATUS_LABELS.REFUNDED,
  [TRANSFERRED_FUNDS]: INVESTMENT_STATUS_LABELS[TRANSFERRED_FUNDS],
}

export const DASHBOARD_INVESTMENT_STATUS_OPTIONS_WITH_SEND_TO_CLIENT = ({
  clientSignatureRequest,
}) => ({
  [INVESTMENT_STATUS.DRAFT]: `${INVESTMENT_STATUS_LABELS.DRAFT}${
    clientSignatureRequest ? ' (Sent to Client)' : ''
  }`,
  [AWAITING_SIGNATURES]: INVESTMENT_STATUS_LABELS[AWAITING_SIGNATURES],
  [SUB_DOC_COMPLETE]: INVESTMENT_STATUS_LABELS[SUB_DOC_COMPLETE],
  [IN_REVIEW_PENDING]: INVESTMENT_STATUS_LABELS[IN_REVIEW_PENDING],
  [APPROVED]: INVESTMENT_STATUS_LABELS[APPROVED],
  [DENIED]: INVESTMENT_STATUS_LABELS[DENIED],
  [ACCEPTED_AND_INVESTED]: INVESTMENT_STATUS_LABELS[ACCEPTED_AND_INVESTED],
  [FA_INVESTMENT_STATUS.NOT_RECEIVED]: INVESTMENT_STATUS_LABELS.NOT_RECEIVED,
  [FA_INVESTMENT_STATUS.RECEIVED]: INVESTMENT_STATUS_LABELS.RECEIVED,
  [FA_INVESTMENT_STATUS.INVESTED]: INVESTMENT_STATUS_LABELS.INVESTED,
  [FA_INVESTMENT_STATUS.CANCELED]: INVESTMENT_STATUS_LABELS.CANCELED,
  [FA_INVESTMENT_STATUS.VOIDED]: INVESTMENT_STATUS_LABELS.VOIDED,
  [FA_INVESTMENT_STATUS.REFUNDED]: INVESTMENT_STATUS_LABELS.REFUNDED,
  [TRANSFERRED_FUNDS]: INVESTMENT_STATUS_LABELS[TRANSFERRED_FUNDS],
})

export const DASHBOARD_INVESTMENT_SELECT_STATUS_OPTIONS = {
  [INVESTMENT_STATUS.DRAFT]: INVESTMENT_STATUS_LABELS.DRAFT,
  [AWAITING_SIGNATURES]: INVESTMENT_STATUS_LABELS[AWAITING_SIGNATURES],
  [SUB_DOC_COMPLETE]: INVESTMENT_STATUS_LABELS[SUB_DOC_COMPLETE],
  [IN_REVIEW_PENDING]: INVESTMENT_STATUS_LABELS[IN_REVIEW_PENDING],
  [APPROVED]: INVESTMENT_STATUS_LABELS[APPROVED],
  [DENIED]: INVESTMENT_STATUS_LABELS[DENIED],
  [ACCEPTED_AND_INVESTED]: INVESTMENT_STATUS_LABELS[ACCEPTED_AND_INVESTED],
  [INVESTMENT_STATUS.CANCELED]: INVESTMENT_STATUS_LABELS.CANCELED,
  [TRANSFERRED_FUNDS]: INVESTMENT_STATUS_LABELS[TRANSFERRED_FUNDS],
}

export const INVESTMENT_ADVISOR = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  individualCrd: PropTypes.string,
  advisorPhone: PropTypes.string,
  firmName: PropTypes.string,
  firmCrd: PropTypes.string,
  uuid: PropTypes.string.isRequired,
}).isRequired

export const INVESTMENT_REG_BI = PropTypes.shape({
  dateInvestorGpcIpp: PropTypes.string,
  recommending: PropTypes.string,
  recommendingType: PropTypes.string,
  accountOfferingInterest: PropTypes.string,
  accountInvestmentDiscus: PropTypes.string,
  beterServeDiscus: PropTypes.string,
  recommendingRollover: PropTypes.string,
  signature: PropTypes.string,
  isSigned: PropTypes.bool,
})

export const InvestmentStatus = PropTypes.oneOf(Object.values(INVESTMENT_STATUS))
export const Investment506cStatus = PropTypes.oneOf(Object.values(INVESTMENT_506C_STATUS))
export const Investment = PropTypes.shape({
  associatedPersonsCount: PropTypes.number,
  associatedSignatures: PropTypes.shape({
    associatedUuid: PropTypes.string,
    name: PropTypes.string,
    sentAt: PropTypes.string,
    signedAt: PropTypes.string,
    status: PropTypes.string,
  }),
  advisorsEnable: PropTypes.bool,
  agreementUrl: PropTypes.string,
  advisor: User,
  amount: PropTypes.number,
  createdAt: PropTypes.string,
  clientSignatureRequest: PropTypes.bool,
  clientSignatureDate: PropTypes.string,
  customSurvey: PropTypes.shape({
    answers: PropTypes.object,
    name: PropTypes.string,
    questions: PropTypes.array,
    surveyUuid: PropTypes.string,
  }),
  additionalFormDocumentUrls: PropTypes.arrayOf(PropTypes.string),
  additionalFormDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    }),
  ),
  emailAuthorization: PropTypes.bool,
  extraFormSubmitted: PropTypes.bool,
  extraFormJointSubmitted: PropTypes.bool,
  fundingId: PropTypes.string,
  hasExtraForm: PropTypes.bool,
  hasTransferForm: PropTypes.bool,
  jointSignatureDate: PropTypes.string,
  advisorSignatureDate: PropTypes.string,
  supervisorSignatureDate: PropTypes.string,
  supervisorRejectionDate: PropTypes.string,
  rejectionNote: PropTypes.string,
  customCheckboxChecked: PropTypes.bool,
  customSurveySubmitted: PropTypes.bool,
  differentBrokerageAccountName: PropTypes.string,
  additionalCustomSurveySubmitted: PropTypes.bool,
  additionalCustomSurvey2Submitted: PropTypes.bool,
  discount: PropTypes.number,
  regBi: INVESTMENT_REG_BI,
  globalSurveySubmitted: PropTypes.bool,
  investorUuid: PropTypes.string,
  investor: Investor,
  investmentAdvisors: PropTypes.arrayOf(INVESTMENT_ADVISOR),
  investmentDistributionInstructions: DistributionInstructions,
  isBlockedByMemberVote: PropTypes.bool,
  isBlockedByMemberVoteAdditional: PropTypes.bool,
  isBlockedByMemberVoteAdditional2: PropTypes.bool,
  isDifferentBrokerageAccountName: PropTypes.bool,
  isJointInvestment: PropTypes.bool,
  isPhxa: PropTypes.bool,
  isSavedToPhxa: PropTypes.bool,
  ippClientSignatureDate: PropTypes.string,
  ippStepAdvisorPassed: PropTypes.bool,
  ippStepInvestorPassed: PropTypes.bool,
  offering: Offering,
  offeringUuid: PropTypes.string,
  offeringIssuerSignatureDate: PropTypes.string,
  paymentMethodId: PropTypes.string,
  paymentMethodType: PropTypes.oneOf(Object.values(PAYMENT_METHOD_TYPE)),
  status: InvestmentStatus,
  status506c: Investment506cStatus,
  note506c: PropTypes.string,
  signatureTemplateItemsSubmitted: PropTypes.bool,
  updatableByCurrentAdvisor: PropTypes.bool,
  updatedAt: PropTypes.string,
  unitClassUuid: PropTypes.string,
  hasBeneficiaries: PropTypes.bool,
  isBeneficiariesValidated: PropTypes.bool,
  uuid: PropTypes.string,
  userAdvisors: PropTypes.arrayOf(User),
})

export const InvestmentAgreement = PropTypes.shape({
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

export const BENEFICIARY_PERSON = PropTypes.shape({
  firstName: PropTypes.string,
  middleInitial: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string,
  taxIdNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPrimary: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['true', 'false'])]),
  percent: PropTypes.number,
})

export const CUSTODIAN = PropTypes.shape({
  name: PropTypes.string,
  street1: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postalCode: PropTypes.string,
  phone: PropTypes.string,
  taxIdNumber: PropTypes.string,
  uuid: PropTypes.string,
})

// DASHBOARD
// -----------------------------------------------------------------------------

export const InvestmentAMLExceptionStatuses = {
  PENDING: 'pending',
  CONTACT_ISSUER: 'contact_issuer',
  CANCELLED: 'cancelled',
  CLEARED: 'cleared',
  VOIDED: 'voided',
}

export const InvestmentAMLExceptionStatusesOptions = {
  [InvestmentAMLExceptionStatuses.PENDING]: 'Pending',
  [InvestmentAMLExceptionStatuses.CONTACT_ISSUER]: 'Contact Issuer',
  [InvestmentAMLExceptionStatuses.CANCELLED]: 'Cancelled',
  [InvestmentAMLExceptionStatuses.CLEARED]: 'Cleared',
  [InvestmentAMLExceptionStatuses.VOIDED]: 'Voided',
}

export const AML_EXCEPTION = PropTypes.shape({
  createdAt: PropTypes.string,
  status: PropTypes.oneOf(Object.values(InvestmentAMLExceptionStatuses)),
  investmentStatus: InvestmentStatus,
  statusUpdates: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  offering: PropTypes.string,
  investorName: PropTypes.string,
  investorEmail: PropTypes.string,
  investorType: PropTypes.string,
})

export const InvestmentAMLException = PropTypes.shape({
  createdAt: PropTypes.string,
  status: PropTypes.oneOf(Object.values(InvestmentAMLExceptionStatuses)),
  investmentStatus: InvestmentStatus,
  offering: PropTypes.string,
  investorName: PropTypes.string,
  investorEmail: PropTypes.string,
  investorType: PropTypes.string,
})

export const BackgroundChecks = PropTypes.shape({
  addressMatches: PropTypes.bool,
  cleared: PropTypes.bool,
  dobMatches: PropTypes.bool,
  nameMatches: PropTypes.bool,
  phoneMatches: PropTypes.bool,
  tinMatches: PropTypes.bool,
  watchlistCleared: PropTypes.bool,
  identityCheck: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  offeringName: PropTypes.string,
  investorName: PropTypes.string,
  investmentAmount: PropTypes.number,
  offering: Offering,
  investor: Investor,
  amlException: AML_EXCEPTION,
})

export const InvestmentsACH = PropTypes.shape({
  amount: PropTypes.number,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  achAuthorizationId: PropTypes.string,
  achId: PropTypes.string,
})

// User Types
// -----------------------------------------------------------------------------
export const USER_TYPE = {
  INVESTOR: 'investor',
  BROKER: 'broker',
  ADVISOR: 'advisor',
  REGISTERED_REPRESENTATIVE: 'registered_representative',
  BUSINESS_ADVISOR: 'business_advisor',
  ISSUER: 'issuer',
  PLATFORM_LICENSEE: 'platform_licensee',
  ADMIN: 'admin',
}

export const USER_TYPES_PUBLIC = [
  USER_TYPE.INVESTOR,
  USER_TYPE.ADVISOR,
  USER_TYPE.REGISTERED_REPRESENTATIVE,
  USER_TYPE.BUSINESS_ADVISOR,
]

export const USER_TYPE_OPTIONS = {
  [USER_TYPE.INVESTOR]: 'Investor',
  [USER_TYPE.BROKER]: 'Broker',
  [USER_TYPE.ADVISOR]: 'Registered Investment Advisor (RIA)',
  [USER_TYPE.REGISTERED_REPRESENTATIVE]: 'Registered Representative',
  [USER_TYPE.BUSINESS_ADVISOR]: 'Business Advisor',
  [USER_TYPE.ISSUER]: 'Issuer',
  [USER_TYPE.PLATFORM_LICENSEE]: 'Platform Licensee',
}
export const USER_TYPE_PUBLIC_OPTIONS = Object.fromEntries(
  USER_TYPES_PUBLIC.map((type) => [type, USER_TYPE_OPTIONS[type]]),
)

export const UserType = PropTypes.oneOf(Object.values(USER_TYPE))

// User Roles
// -----------------------------------------------------------------------------
export const USER_ROLE = {
  INVESTOR: 'ROLE_INVESTOR',
  ACCOUNTANT: 'ROLE_ACCOUNTANT',
  ATTORNEY: 'ROLE_ATTORNEY',
  ADVISOR: 'ROLE_ADVISOR',
  REGISTERED_REPRESENTATIVE: 'ROLE_REGISTERED_REPRESENTATIVE',
  REGISTERED_BROKER_DEALER: 'ROLE_REGISTERED_BROKER_DEALER',
  DELEGATE: 'ROLE_DELEGATE',
  BUSINESS_ADVISOR: 'ROLE_BUSINESS_ADVISOR',
  SUPERVISOR: 'ROLE_SUPERVISOR',
  CLIENT: 'ROLE_ADVISOR_CLIENT',
  ADMIN: 'ROLE_ADMIN',
  LIMITED_ADMIN: 'ROLE_LIMITED_ADMIN',
  PLATFORM_LICENSEE: 'ROLE_PLATFORM_LICENSEE',
}

export const SUPERVISOR_FOR_ADVISOR = 'ROLE_SUPERVISOR_FOR_ADVISOR'

export const USER_ROLES_PUBLIC = [
  USER_ROLE.INVESTOR,
  USER_ROLE.ADVISOR,
  USER_ROLE.REGISTERED_REPRESENTATIVE,
  USER_ROLE.REGISTERED_BROKER_DEALER,
  USER_ROLE.DELEGATE,
  // USER_ROLE.SUPERVISOR,
  USER_ROLE.BUSINESS_ADVISOR,
  USER_ROLE.ATTORNEY,
  USER_ROLE.ACCOUNTANT,
]

export const USER_ROLE_OPTIONS = {
  [USER_ROLE.INVESTOR]: 'Investor',
  [USER_ROLE.ADVISOR]: 'Registered Investment Advisor (RIA)',
  [USER_ROLE.REGISTERED_REPRESENTATIVE]: 'Registered Representative',
  [USER_ROLE.REGISTERED_BROKER_DEALER]: 'Registered Broker-Dealer',
  [USER_ROLE.DELEGATE]: 'Delegate',
  [USER_ROLE.BUSINESS_ADVISOR]: 'Business Advisor',
  [USER_ROLE.SUPERVISOR]: 'Supervisor',
  [USER_ROLE.ATTORNEY]: 'Licensed Attorney',
  [USER_ROLE.ACCOUNTANT]: 'Certified Public Accountant',
  [USER_ROLE.PLATFORM_LICENSEE]: 'Platform Licensee',
  [USER_ROLE.LIMITED_ADMIN]: 'Limited Admin',
}

export const USER_ROLE_PUBLIC_OPTIONS = Object.fromEntries(
  USER_ROLES_PUBLIC.map((role) => [role, USER_ROLE_OPTIONS[role]]),
)

export const UserRole = PropTypes.oneOf(Object.values(USER_ROLE))

// User
// -----------------------------------------------------------------------------
export const User = PropTypes.shape({
  confirmed: PropTypes.bool,
  createdAt: PropTypes.string.isRequired,
  dashboard: PropTypes.bool,
  email: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  enableAdvisorAutoAssignmentToNewInvestors: PropTypes.string,
  hasPassword: PropTypes.bool,
  name: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(UserRole).isRequired,
  // type: UserType.isRequired,
  updatedAt: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,

  //advisor's and supervisor additional fields
  advisorPhone: PropTypes.string,
  firmName: PropTypes.string,
  individualCrd: PropTypes.string,

  //supervisor additional fields
  supervisorAccess: PropTypes.bool,
  supervisorAccessGranted: PropTypes.bool,

  additionalData: PropTypes.shape({
    //advisor's additional data
    financialNumber: PropTypes.string,
    branchNumber: PropTypes.string,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postalCode: PropTypes.string,
    firmCrd: PropTypes.string,
    personalStreetAddress: PropTypes.string,
    personalCity: PropTypes.string,
    personalRegion: PropTypes.string,
    personalPostalCode: PropTypes.string,
    personalPhone: PropTypes.string,

    //attorney's additional fields
    attorneyLicenseId: PropTypes.string,
    //accountant's additional fields
    cpaLicenseId: PropTypes.string,
    portalData: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      uuid: PropTypes.string,
    }),
    restrictDashboardAccess: PropTypes.bool,
    dashboardEntitlements: PropTypes.object,
  }),
})
export const SUPERVISOR_APPROVED = true
export const SUPERVISOR_DISAPPROVED = false
export const USER_SUPERVISOR_ACCESS_OPTIONS = {
  [SUPERVISOR_APPROVED]: 'Yes',
  [SUPERVISOR_DISAPPROVED]: 'No',
}

export const SUPERVISOR_ACCESS_KEY = 'extend_for_supervisor'

export const AdvisorRequiredFields = [
  'firmName',
  'streetAddress',
  'city',
  'region',
  'postalCode',
  'firmCrd',
  'advisorPhone',
  'individualCrd',
  'personalStreetAddress',
  'personalCity',
  'personalRegion',
  'personalPostalCode',
  'personalPhone',
]

export const userActivityPageVisitType = 'page_visit'
export const userActivityButtonClickType = 'button_click'
export const userActivityDocumentDownloadType = 'document_download'
export const userImpersonated = 'user_impersonated'

export const USER_ACTIVITY_OPTIONS = {
  [userActivityPageVisitType]: 'Page Visit',
  [userActivityButtonClickType]: 'Button Click',
  [userActivityDocumentDownloadType]: 'Document Download',
  [userImpersonated]: 'Log In As',
}

export const UserActivity = PropTypes.shape({
  activityType: PropTypes.string,
  activityData: PropTypes.object,
  user: User,
  ipAddress: PropTypes.string,
  createdAt: PropTypes.string,
  uuid: PropTypes.string,
})

export const ReportsDocumentPDFType = 'pdf'
export const ReportsDocumentXLSXType = 'xlsx'

export const ReportsUserActivityType = 'user_activity'
export const ReportsDocumentSubscriptionAgreementType = 'subscription_agreements'
export const ReportsInvestorFilesType = 'investor_files'

export const REPORT_TYPES_OPTIONS = {
  [ReportsUserActivityType]: 'User Activity',
  [ReportsDocumentSubscriptionAgreementType]: 'Subscription Agreements',
  [ReportsInvestorFilesType]: 'Investors files',
}

export const ReportsDocumentTypes = {
  pdf: ReportsDocumentPDFType,
  xlsx: ReportsDocumentXLSXType,
  sa: ReportsDocumentSubscriptionAgreementType,
}

export const REPORT_ENTITIES = {
  userActivity: ReportsUserActivityType,
  users: 'users',
  investments: 'investments',
  investors: 'investors',
  offerings: 'offerings',
}

export const ReportStatuses = {
  init: 'init',
  error: 'error',
  inProgress: 'in_progress',
  generated: 'generated',
}
export const ReportStatusesLabels = {
  [ReportStatuses.init]: 'Init',
  [ReportStatuses.error]: 'Error',
  [ReportStatuses.inProgress]: 'In Progress',
  [ReportStatuses.generated]: 'Generated',
}

export const UserActivityReport = {
  status: PropTypes.oneOf(Object.values(ReportStatuses)),
  errorMessage: PropTypes.string,
  reportExtension: PropTypes.string,
  reportType: PropTypes.string,
  uuid: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
}

export const ClientStat = PropTypes.shape({
  confirmed: PropTypes.bool,
  email: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(UserRole).isRequired,
  // type: UserType.isRequired,
  uuid: PropTypes.string.isRequired,
  profiles: PropTypes.number.isRequired,
  draftInvestments: PropTypes.number.isRequired,
  notSignedInvestments: PropTypes.number.isRequired,
  pendingInvestments: PropTypes.number.isRequired,
  totalInvestments: PropTypes.number.isRequired,
})

// Country
// -----------------------------------------------------------------------------
export const Country = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

// State
// -----------------------------------------------------------------------------
export const State = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

// Portal Pages
// -----------------------------------------------------------------------------
export const PORTAL_PAGE_TYPE = {
  PAGE: 'default',
  LANDING_BLOCK: 'landing',
}

export const PortalPage = PropTypes.shape({
  weight: PropTypes.number,
  content: PropTypes.string,
  menu: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
})

// LISTS
// -----------------------------------------------------------------------------
export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
  DEFAULT: 'asc',
}

// IsFieldValue
// -----------------------------------------------------------------------------
export const OPERATORS = {
  BLANK: 'blank',
  EQUALS: 'equals',
  ONE_OF: 'oneOf',
  ALL_OF: 'allOf',
  NOT: 'not',
}

// Questions
// -----------------------------------------------------------------------------
export const QUESTION_TYPE = {
  CHECKBOX: 'checkbox_single',
  CHECKBOX_GROUP: 'checkbox_group',
  CURRENCY: 'currency',
  DATE: 'date',
  EMAIL: 'email',
  NUMBER: 'number',
  PHONE: 'phone',
  RADIO_BUTTON_GROUP: 'radio_button_group',
  SELECT: 'select',
  TEXTAREA: 'textarea',
  TEXT: 'text',
  STATIC_CONTENT: 'static_content',
  SEPARATOR: 'separator',
  HTML: 'html',
}
export const QuestionType = PropTypes.oneOf(Object.values(QUESTION_TYPE))

export const QuestionProps = {
  content: PropTypes.string,
  correctAnswers: PropTypes.string,
  dependsOn: PropTypes.shape({
    name: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  isMemberVote: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
    }),
  ),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(QUESTION_TYPE)),
  uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export const Question = PropTypes.shape({
  ...QuestionProps,
  showCreated: PropTypes.bool,
  showUpdated: PropTypes.bool,
  withCorrectAnswers: PropTypes.bool,
  disabled: PropTypes.bool,
})

//CONFIRMATION ACCREDITATION ATTEMPTS

export const ConfirmationAccreditationAttemptsStatuses = {
  new: 'new',
  confirmed: 'confirmed',
  refused: 'refused',
}

const statusesArr = Object.values(ConfirmationAccreditationAttemptsStatuses).filter(
  (e) => typeof e === 'string',
)

export const ConfirmationAccreditationAttempts = PropTypes.shape({
  investorName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.oneOf(statusesArr).isRequired,
  uuid: PropTypes.string.isRequired,
})

export const OfferingsUpdatesPost = PropTypes.shape({
  title: PropTypes.string,
  content: PropTypes.string,
  weight: PropTypes.number,
  isVisible: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  offering: Offering,
})

// REPLACED post to post-json in the initial url
export const CONTACT_US_URL =
  'https://1776ing.us1.list-manage.com/subscribe/post-json?u=e1ae1f195b37c7674fc45b323&id=281754adb3'

export const ESCROW_DISBURSEMENTS_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  DISBURSED: 'disbursed',
  VOIDED: 'voided',
  FAILED: 'failed',
}

export const EscrowDisbursementsStatusOptions = {
  [ESCROW_DISBURSEMENTS_STATUS.PENDING]: 'Pending',
  [ESCROW_DISBURSEMENTS_STATUS.PROCESSING]: 'Processing',
  [ESCROW_DISBURSEMENTS_STATUS.DISBURSED]: 'Disbursed',
  [ESCROW_DISBURSEMENTS_STATUS.VOIDED]: 'Voided',
  [ESCROW_DISBURSEMENTS_STATUS.FAILED]: 'Failed',
}

export const ESCROW_DISBURSEMENTS = PropTypes.shape({
  bankTransferMethod: PropTypes.shape({
    accountNumberShort: PropTypes.string,
    bankName: PropTypes.string,
    country: PropTypes.string,
    intermediaryBankName: PropTypes.string,
    intermediaryAccountNumber: PropTypes.string,
    intermediaryRoutingNumber: PropTypes.string,
    name: PropTypes.string,
    nameOnAccount: PropTypes.string,
    swiftCode: PropTypes.string,
    type: PropTypes.string,
    verified: PropTypes.bool,
    faCreatedAt: PropTypes.string,
    faUpdatedAt: PropTypes.string,
    cancelledAt: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    uuid: PropTypes.string,
    investorUuid: PropTypes.string,
    escrowDisbursementUuid: PropTypes.string,
  }),
  amount: PropTypes.number,
  checkMailingAddress: PropTypes.string,
  checkPayee: PropTypes.string,
  city: PropTypes.string,
  contactName: PropTypes.string,
  country: PropTypes.string,
  faCreatedAt: PropTypes.string,
  disbursedAt: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  paymentDetails: PropTypes.string,
  paymentMethod: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  reference: PropTypes.string,
  region: PropTypes.string,
  status: PropTypes.string,
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  faUpdatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  offeringName: PropTypes.string,
})

export const REQUEST_STATUS_INITIATED = 'initiated'
export const REQUEST_STATUS_APPROVED = 'approved'
export const REQUEST_STATUS_DENIED = 'denied'

export const REQUEST_STATUS_CHOICES = {
  [REQUEST_STATUS_INITIATED]: 'Initiated',
  [REQUEST_STATUS_APPROVED]: 'Approved',
  [REQUEST_STATUS_DENIED]: 'Denied',
}

export const SECTION_REQUEST = {
  applicantUuid: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  objectUuid: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(REQUEST_STATUS_CHOICES)),
  updatedAt: PropTypes.string.isRequired,
}

//506-C Verification Types

//old link
// export const TEMPLATE_LINK = '/app/download-static-files/1776ing-506c-template.pdf'

export const TEMPLATE_LINK_GLOBAL = '/app/download-static-files/1776ing-506c-template-global.pdf'
export const TEMPLATE_LINK_PCM = '/app/download-static-files/1776ing-506c-template-pcm.pdf'

export const ADVISOR_APPROVED = 'advisor'
export const INCOME_VERIFICATION = 'gatherSpecificDocuments'
export const WISEMAN_VERIFICATION = 'email'
export const SELF_CERTIFIED = 'finra'
export const ACCREDITATION_STATUS_DOCUMENT = 'thirdParty'
export const OFFLINE = 'offline'

export const Verification506cOptions = {
  [ADVISOR_APPROVED]:
    'Your financial advisor has already attested to your accredited investor status.',
  [INCOME_VERIFICATION]:
    'We must gather very specific information found on documents from you that are recent. This is to verify the last two years of your income, or your (or your Entities) Net Worth (not including your personal residence). This is done by you uploading documents and our team reviewing them. I reasonably expect to earn an individual income of at least $200,000 this year or joint income with my spouse of at least $300,000 this year. For net worth, you and your spouse (if applicable) will need to represent that all liabilities have been disclosed. My individual net worth, or my joint net worth together with my spouse, exceeds $1,000,000. We will keep these documents secure, confidential and only use them to confirm your accreditation status and enable you to make this investment.',
  [WISEMAN_VERIFICATION]:
    'We can rely on a SEC-registered Financial Adviser, licensed CPA or licensed Attorney who already knows you to attest to your accredited status.  This is done via automated forms and email.  It’s simple and easy for you and your professional advisor.',
  [SELF_CERTIFIED]:
    'You fall into a narrow category of investors whom can self-certify if you are licensed with a FINRA series 7, 65 or 82 in good standing, or are an employee of the Issuer.',
  [ACCREDITATION_STATUS_DOCUMENT]:
    'You already have an existing 3rd Party Accreditation Status Document for the exact name/entity you are using to invest in this offering and obtained within five years of your investment, and you presently confirm in writing you are still an accredited investor today.',
  [OFFLINE]: 'You prefer to get this done offline.',
}

export const Verification506cOptionsAdvisor = {
  [ADVISOR_APPROVED]:
    'As the client’s advisor, I have taken reasonable steps to verify that my client is an accredited investor in the last three months, and attest to their Accredited Investor Status.',
  [INCOME_VERIFICATION]:
    'We must gather very specific information found on documents from you that are recent. This is to verify the last two years of your income, or your (or your Entities) Net Worth (not including your personal residence). This is done by you uploading documents and our team reviewing them. I reasonably expect to earn an individual income of at least $200,000 this year or joint income with my spouse of at least $300,000 this year. For net worth, you and your spouse (if applicable) will need to represent that all liabilities have been disclosed. My individual net worth, or my joint net worth together with my spouse, exceeds $1,000,000. We will keep these documents secure, confidential and only use them to confirm your accreditation status and enable you to make this investment.',
  [WISEMAN_VERIFICATION]:
    'We can rely on a SEC-registered Financial Adviser, licensed CPA or licensed Attorney who already knows you to attest to your accredited status.  This is done via automated forms and email.  It’s simple and easy for you and your professional advisor.',
  [SELF_CERTIFIED]:
    'You fall into a narrow category of investors whom can self-certify if you are licensed with a FINRA series 7, 65 or 82 in good standing, or are an employee of the Issuer. ',
  [ACCREDITATION_STATUS_DOCUMENT]:
    'You already have an existing 3rd Party Accreditation Status Document for the exact name/entity you are using to invest in this offering and obtained within five years of your investment, and you presently confirm in writing you are still an accredited investor today.',
  [OFFLINE]: 'You prefer to get this done offline.',
}

export const INVESTOR_CHECKED = 'investorChecked'
export const ADVISOR_CHECKED = 'advisorChecked'
export const IS_BROKER_DEALER_OR_SEC = 'IsBrokerDealerOrSec'

export const ACCREDITATION_ATTEMPT = {
  type: PropTypes.oneOf(Object.keys(Verification506cOptionsAdvisor)),
  status: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  approvedByIssuerAt: PropTypes.string,
}

export const NET_WORTH = 'netWorth'
export const ANNUAL_INCOME = 'annualIncome'

export const CUSTOM_SA_FILE = 'custom sa'

export const INCOME_DOCUMENT_TYPE_OPTIONS = {
  [NET_WORTH]: 'Net Worth',
  [ANNUAL_INCOME]: 'Annual Income',
}

const ANNUAL_INCOME_OPTION_W2 = 'W2'
const ANNUAL_INCOME_OPTION_1099E = 1099
const ANNUAL_INCOME_OPTION_K1_1065 = 'K-1/1065'
const ANNUAL_INCOME_OPTION_IRS_FORM_1040 = 'IRS1040'

export const ANNUAL_INCOME_OPTION_OTHER = 'otherAnnualIncome'

export const ANNUAL_INCOME_OPTIONS = {
  [ANNUAL_INCOME_OPTION_W2]: 'W2',
  [ANNUAL_INCOME_OPTION_1099E]: 1099,
  [ANNUAL_INCOME_OPTION_K1_1065]: 'K-1/1065',
  [ANNUAL_INCOME_OPTION_IRS_FORM_1040]: 'IRS Form 1040',
  [ANNUAL_INCOME_OPTION_OTHER]: 'Other',
}

const NET_WORTH_OPTION_BANK_STATEMENTS = 'bankStatements'
const NET_WORTH_OPTION_BROKERAGE_STATEMENTS = 'brokerageStatements'
const NET_WORTH_OPTION_OTHER_STATEMENTS_OF_SECURITIES_HOLDINGS = 'otherStatements'
const NET_WORTH_OPTION_CERTIFICATES_OF_DEPOSIT = 'certificates of deposit'
const NET_WORTH_OPTION_TAX_ASSESSMENTS = 'taxAssessments'
const NET_WORTH_OPTION_APPRAISAL_REPORTS_ISSUED_BY_INDEPENDENT_THIRD_PARTIES = 'thirdPartiesReports'
const NET_WORTH_OPTION_PERSONAL_FINANCIAL_STATEMENTS = 'personalStatements'
const NET_WORTH_OPTION_LIABILITIES = 'liabilities'

export const NET_WORTH_INCOME_OPTION_OTHER = 'otherNetWorthIncome'

export const NET_WORTH_DOCUMENTS_TYPE_OPTIONS = {
  [NET_WORTH_OPTION_BANK_STATEMENTS]: 'Bank Statements',
  [NET_WORTH_OPTION_BROKERAGE_STATEMENTS]: 'Brokerage Statements',
  [NET_WORTH_OPTION_OTHER_STATEMENTS_OF_SECURITIES_HOLDINGS]:
    'Other Statements Of Securities Holdings',
  [NET_WORTH_OPTION_CERTIFICATES_OF_DEPOSIT]: 'Certificates Of Deposit',
  [NET_WORTH_OPTION_TAX_ASSESSMENTS]: 'Tax Assessments',
  [NET_WORTH_OPTION_APPRAISAL_REPORTS_ISSUED_BY_INDEPENDENT_THIRD_PARTIES]:
    'Appraisal Reports Issued By Independent Third Parties',
  [NET_WORTH_OPTION_PERSONAL_FINANCIAL_STATEMENTS]: 'Personal Financial Statements',
  [NET_WORTH_OPTION_LIABILITIES]: 'Liabilities',
  [NET_WORTH_INCOME_OPTION_OTHER]: 'Other',
}

export const SEC_REGISTERED = 'secRegistered'
export const REPRESENTATIVE_LICENSE = 'representativeLicense'
export const DIRECTOR = 'director'
export const KNOWLEDGEABLE_EMPLOYEE = 'knowledgeableEmployee'

export const SELF_CERTIFY_CATEGORIES_OPTIONS = {
  [SEC_REGISTERED]:
    'An SEC-registered broker-dealer, SEC- or state-registered investment adviser, or exempt reporting adviser',
  [REPRESENTATIVE_LICENSE]:
    'An individual holding in good standing any of the general securities representative license (Series 7), the investment adviser representative license (Series 65), or the private securities offerings representative license (Series 82).',
  [DIRECTOR]:
    'A director, executive officer, or general partner of the company selling the securities, or any director, executive officer, or general partner of a general partner of that company.',
  [KNOWLEDGEABLE_EMPLOYEE]:
    'A knowledgeable employee, as defined in rule 3c-5(a)(4) under the Investment Company Act, of the issuer of securities where that issuer is a 3(c)(1) or 3(c)(7) private fund',
}

export const ACCREDITATION_STATUS_NEW = 'new'

export const CONFIRMATION_ACCREDITATION = PropTypes.shape({
  attributesValues: PropTypes.shape({
    investorChecked: PropTypes.bool,
    incomeType: PropTypes.oneOf(Object.keys(INCOME_DOCUMENT_TYPE_OPTIONS)),
    finraCategory: PropTypes.bool,
  }),
  createdBy: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(Verification506cOptionsAdvisor)),
  uuid: PropTypes.string.isRequired,
})

export const VERIFICATION506C = 'verification506c'

//Session tracker
export const SESSION_EXPIRED_TIME_KEY = 'sessionExpiredTime'

//Tin Check
export const TinCheckRequestCodesOptions = {
  '-1': '	Request Not Processed',
  0: '	Request In Process',
  1: '	Request Completed',
  2: '	Request Exist in DB',
  3: '	Request sent to Authority',
  10: '	Login denied: Invalid User Login and/or Password',
  11: '	Invalid Data',
  12: '	Invalid Company Configuration: Tin/Name, List Match and Address Validations are disabled',
  13: '	Invalid Request',
  14: '	Duplicate Request',
  15: '	Connection Problem',
  17: '	Service temporarily unavailable',
  18: '	Request denied: no more checks available for this account. Please, contact TinCheck to renew your account',
  20: '	Processing Error',
  23: '	Login denied: Invalid User',
  24: '	Login denied: Invalid Password',
  25: '	Login denied: This Account has been locked',
  26: '	Login denied: Account Locked for 24-Hours: 3 or more Login attempts',
  27: '	Login denied: Terms not accepted',
  28: '	Login denied: Account Expired',
  29: '	Login denied: No Security rights',
}

export const TinCheckTINNAMEOptions = {
  '-1': 'Tin/Name Validation Not Processed',
  0: 'TIN and Name combination does not match IRS records',
  1: 'TIN and Name combination matches IRS records',
  5: 'TIN entered is not currently issued',
  6: 'TIN and Name combination matches IRS SSN records',
  7: 'TIN and Name combination matches IRS EIN records',
  8: 'TIN and Name combination matches IRS SSN and EIN records',
  10: 'Login denied: Invalid User Login and/or Password',
  11: 'Invalid Data',
  12: 'Invalid Configuration',
  13: 'Invalid TIN Matching request',
  14: 'Duplicate TIN Matching request',
  15: 'Connection Problem',
  16: 'IRS Connection Problem',
  17: 'IRS Tin/Name Validation temporarily unavailable',
  18: 'Request denied: no more checks available for this account. Please, contact TinCheck to renew your account',
  20: 'Processing Error',
  21: 'IRS Processing Error',
  22: 'Invalid IRS Login',
  23: 'Login denied: Invalid User',
  24: 'Login denied: Invalid Password',
  25: 'Login denied: This Account has been locked',
  26: 'Login denied: Account Locked for 24-Hours: 3 or more Login attempts',
  27: 'Login denied: Terms not accepted',
  28: 'Login denied: Account Expired',
  29: 'Login denied: No Security rights',
}

export const TinCheckDMFOptions = {
  '-1': 'Death Master File Match Not Processed',
  0: 'No Death Master File Match Found',
  1: 'Possible Death Master File Match Found',
  10: 'Login denied: Invalid User Login and/or Password',
  11: 'Invalid Data',
  12: 'Invalid Configuration',
  13: 'Invalid Death Master File Matching request',
  15: 'Connection problem',
  16: 'DMF Connection Problem',
  17: 'Death Master File Match temporarily unavailable',
  18: 'Request denied: no more checks available for this account. Please, contact TinCheck to renew your account',
  20: 'Processing Error',
  21: 'Death Master File Processing Error',
  23: 'Login denied: Invalid User',
  24: 'Login denied: Invalid Password',
  25: 'Login denied: This Account has been locked',
  26: 'Login denied: Account Locked for 24-Hours: 3 or more Login attempts',
  27: 'Login denied: Terms not accepted',
  28: 'Login denied: Account Expired',
  29: 'Login denied: No Security rights',
}

export const TinCheckEINOptions = {
  '-1': 'EIN Match Not Processed',
  0: 'No EIN Match found.',
  1: 'EIN Possible Match found.',
  10: 'Login denied: Invalid User Login and/or Password',
  11: 'Invalid Data',
  12: 'Invalid Configuration',
  13: 'Invalid Death Master File Matching request',
  15: 'Connection problem',
  16: 'DMF Connection Problem',
  17: 'Death Master File Match temporarily unavailable',
  18: 'Request denied: no more checks available for this account. Please, contact TinCheck to renew your account',
  20: 'Processing Error',
  21: 'Death Master File Processing Error',
  23: 'Login denied: Invalid User',
  24: 'Login denied: Invalid Password',
  25: 'Login denied: This Account has been locked',
  26: 'Login denied: Account Locked for 24-Hours: 3 or more Login attempts',
  27: 'Login denied: Terms not accepted',
  28: 'Login denied: Account Expired',
  29: 'Login denied: No Security rights',
}

export const TinCheckValidateListMatchCodesOptions = {
  '-1': 'Lists Match Not Processed',
  0: 'No Lists Matches Found',
  1: 'Possible Lists Matches Found',
  10: 'Login denied: Invalid User Login and/or Password',
  11: 'Problem processing List Match Vaidation request: Invalid Data. {Details}',
  12: 'Invalid Configuration: No Lists enabled',
  13: 'Invalid Lists Matching request',
  15: 'Connection problem',
  16: 'Lists Connection Problem',
  17: 'Lists Match temporarily unavailable',
  18: 'Request denied: no more checks available for this account. Please, contact TinCheck to renew your account',
  20: 'Processing Error',
  21: 'Lists Match Processing Error',
  23: 'Login denied: Invalid User',
  24: 'Login denied: Invalid Password',
  25: 'Login denied: This Account has been locked',
  26: 'Login denied: Account Locked for 24-Hours: 3 or more Login attempts',
  27: 'Login denied: Terms not accepted',
  28: 'Login denied: Account Expired',
  29: 'Login denied: No Security rights',
}

export const TIN_CHECK_RESULT = PropTypes.shape({
  Code: PropTypes.string,
  Data: PropTypes.string,
  Type: PropTypes.string,
  Count: PropTypes.string,
  Details: PropTypes.string,
})

export const TIN_CHECK = PropTypes.shape({
  investor: PropTypes.string,
  request: PropTypes.object,
  response: PropTypes.shape({
    ValidateTinNameAddressListMatchResult: PropTypes.shape({
      REQUESTID: PropTypes.string,
      REQUEST_DETAILS: PropTypes.string,
      REQUEST_STATUS: PropTypes.string,
      TINNAME_RESULT: PropTypes.shape({
        TINNAME_CODE: PropTypes.string,
        TINNAME_DETAILS: PropTypes.string,
        DMF_CODE: PropTypes.string,
        DMF_DETAILS: PropTypes.string,
        DMF_DATA: PropTypes.string,
        EIN_CODE: PropTypes.string,
        EIN_DETAILS: PropTypes.string,
        EIN_DATA: PropTypes.string,
      }),
      STATUS: PropTypes.shape({
        CallsRemaining: PropTypes.string,
        Status: PropTypes.string,
      }),
      LISTMATCH_RESULT: PropTypes.shape({
        LISTSMATCH_CODE: PropTypes.string,
        LISTSMATCH_DETAILS: PropTypes.string,
        Results: PropTypes.shape({
          Result: PropTypes.arrayOf(TIN_CHECK_RESULT),
        }),
      }),
    }),
  }),
  uuid: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const IMPORT_DOCUMENT = PropTypes.shape({
  matchedInvestor: PropTypes.shape({
    uuid: PropTypes.string,
    fullTaxIdNumber: PropTypes.string,
  }),
  parsedInvestorName: PropTypes.string,
  parsedTaxIdNumber: PropTypes.string,
  matchedInvestorUuid: PropTypes.string,
  uuid: PropTypes.string,
  fileName: PropTypes.string,
  hasNotes: PropTypes.bool,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      text: PropTypes.string,
      updatedAt: PropTypes.string,
      uuid: PropTypes.string,
    }),
  ),
})

export const FILE_NOTE = {
  user: User,
  text: PropTypes.string,
  uuid: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
}

export const FILE_STATUSES = {
  statusInit: 'init',
  statusMatched: 'matched',
  partiallyMatched: 'partially_matched',
  statusNotMatched: 'not_matched',
  statusApproved: 'approved',
  statusDeclined: 'declined',
  statusImported: 'imported',
}

export const FILE_STATUSES_OPTIONS = {
  [FILE_STATUSES.statusMatched]: 'Matched',
  [FILE_STATUSES.partiallyMatched]: 'Partially Matched',
  [FILE_STATUSES.statusNotMatched]: 'Not Matched',
  [FILE_STATUSES.statusApproved]: 'Approved',
  [FILE_STATUSES.statusDeclined]: 'Declined',
  [FILE_STATUSES.statusImported]: 'Imported',
}

export const QUEUE_STATUSES = {
  statusInit: 'init',
  statusPartiallyMatched: 'partially_matched',
  statusMatched: 'matched',
  statusNotMatched: 'not_matched',
  statusImported: 'imported',
  statusPartiallyImported: 'partially_imported',
  statusImportInProgress: 'import_in_progress',
}

export const EXTENDED_INVESTMENT_KEY = 'extended_investment'

export const DECLINED_STATUSES = [FILE_STATUSES.statusInit, FILE_STATUSES.statusImported]

export const EMAIL_STATUSES = {
  statusSent: 'sent',
  statusQueued: 'queued',
  statusFailed: 'failed',
}

export const EMAIL_STATUSES_OPTIONS = {
  [EMAIL_STATUSES.statusSent]: 'Sent',
  [EMAIL_STATUSES.statusQueued]: 'Queued',
  [EMAIL_STATUSES.statusFailed]: 'Failed',
}

export const TRUE_FALSE_OPTIONS = {
  true: 'Yes',
  false: 'No',
  null: 'Any',
}
